import React from 'react';
import PropTypes from 'prop-types';
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {withStyles} from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Table from "@material-ui/core/Table";

const styles = theme => ({
    paper: {
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    contentWrapper: {
        margin: '40px 26px',
    },
});



const OfferLog = props => {
    const {classes, offerHistory} = props;
    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <h3>Historikk</h3>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell algin="left">Beskrivelse</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="left">Registrert av</TableCell>
                            <TableCell align="left">Dato</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offerHistory.map((row,index) => (
                            <TableRow className={ classes.itemRow } key={index}>
                                <TableCell align="left">{row.description}</TableCell>
                                <TableCell align="left">{row.offerStatus.status}</TableCell>
                                <TableCell align="left">{row.registeredByUser.fullname}</TableCell>
                                <TableCell align="left"><Moment>{row.registeredDate}</Moment></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </Paper>
    );
};

OfferLog.propTypes = {
    offerHistory: PropTypes.array.isRequired,
};

export default withStyles(styles)(OfferLog);