import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import CategoryIcon from '@material-ui/icons/Category';
import history from "../../helpers/historyHelper";
import PdfPreview from "../Pages/PdfPreview";

let categories = [];

const styles = theme => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: 10,
        paddingBottom: 10,
    },
    firebase: {
        fontSize: 20,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
});

function Navigator(props) {
    let user = JSON.parse(localStorage.getItem('user'));
    const { classes, ...other } = props;
    if(user && user.organizationId === "") {
        categories = [
            {
                id: 'Retningslinjer',
                children: [
                    { id: 'Innledning', link: '#', pages: [2]},
                    { id: 'Generelle krav', link: '#', pages: [3,4,5]},
                    { id: 'Krav til:', link: '#', pages: []},
                    { id: '-- Luseskjørt', link: '#', pages: [6]},
                    { id: '-- Forspredere og Forslanger', link: '#', pages: [7]},
                    { id: '-- El-skap og tilsvarende skap', link: '#', pages: [8]},
                    { id: '-- Undervannskamera og veierammer', link: '#', pages: [9]},
                    { id: '-- Rensefiskutstyr i merd', link: '#', pages: [10]},
                    { id: '-- Fugletak og holdere', link: '#', pages: [11]},
                    { id: '-- Systemer for opptak av dødfisk', link: '#', pages: [12]},
                    { id: '-- Sensorer, diffusorer og undervannslys', link: '#', pages: [13]},
                    { id: '-- Fôrautomater for rensefisk', link: '#', pages: [14]},
                    { id: '-- Nedloddingssystem', link: '#', pages: [15]},
                    { id: '-- Leveringsline, kuleline og avkastnot', link: '#', pages: [16]},
                    { id: '-- Autonome vaskeroboter', link: '#', pages: [17]}
                ]
            }
        ];
        
        if(user.role === "Normal") {
            categories = [
                {
                    id: 'Saksbehandling',
                    children: [
                        { id: 'Mine saker', icon: <PermMediaOutlinedIcon />, link: '/MyCases' },
                    ],
                },
                ...categories
            ];
        }

        if(user.role === "Admin") {
            categories = [
                {
                    id: 'Saksbehandling',
                    children: [
                        { id: 'Mine saker', icon: <PermMediaOutlinedIcon />, link: '/MyCases' },
                        { id: 'Godkjent', icon: <PermMediaOutlinedIcon />, link: '/ApprovedCases' },
                        { id: 'Avslag', icon: <PermMediaOutlinedIcon />, link: '/DismissedCases' },
                    ],
                },
                ...categories
            ];
        }
        
        if(user.role === "SuperAdmin") {
            categories = [
                {
                    id: 'Saksbehandling',
                    children: [
                        { id: 'Alle saker', icon: <PermMediaOutlinedIcon/>, link: '/AllCases' },
                        { id: 'Innkommenede', icon: <PermMediaOutlinedIcon />, link: '/IncomingCases' },
                        { id: 'Mine saker', icon: <PermMediaOutlinedIcon />, link: '/MyCases' },
                        { id: 'Til godkjenning', icon: <PermMediaOutlinedIcon />, link: '/CasesForApproval' },
                        { id: 'Godkjent', icon: <PermMediaOutlinedIcon />, link: '/ApprovedCases' },
                        { id: 'Avslag', icon: <PermMediaOutlinedIcon />, link: '/DismissedCases' },
                    ],
                },
                ...categories,
                {
                    id: 'Intranet',
                    children: [
                        { id: 'Leverandører', icon: <BusinessCenterIcon />, link: '/intranet/suppliers'},
                        { id: 'Utstyrskategorier', icon: <CategoryIcon />, link: '/intranet/equipmentcategories'},
                        { id: 'Brukere', icon: <BusinessCenterIcon />, link: '/intranet/users'}
                    ]
                }
            ];
        }
    } else {
        categories = [
            {
                id: 'Leverandørområde',
                children: [
                    { id: 'Firmaerklæring', icon: <BusinessCenterIcon />, link: '/Organization/Declarations' },
                    { id: 'Brukere', icon: <BusinessCenterIcon />, link: '/Organization/Users' },
                ],
            },
            {
                id: 'Ekstrautstyr',
                children: [
                    { id: 'Produkter', icon: <PermMediaOutlinedIcon />, link: '/Home' },
                ]
            },
            {
                id: 'LSG Standard',
                children: [
                    { id: 'Innledning', link: '#', pages: [2]},
                    { id: 'Generelle krav', link: '#', pages: [3,4,5]},
                    { id: 'Krav til:', link: '#', pages: []},
                    { id: '-- Luseskjørt', link: '#', pages: [6]},
                    { id: '-- Forspredere og Forslanger', link: '#', pages: [7]},
                    { id: '-- El-skap og tilsvarende skap', link: '#', pages: [8]},
                    { id: '-- Undervannskamera og veierammer', link: '#', pages: [9]},
                    { id: '-- Rensefiskutstyr i merd', link: '#', pages: [10]},
                    { id: '-- Fugletak og holdere', link: '#', pages: [11]},
                    { id: '-- Systemer for opptak av dødfisk', link: '#', pages: [12]},
                    { id: '-- Sensorer, diffusorer og undervannslys', link: '#', pages: [13]},
                    { id: '-- Fôrautomater for rensefisk', link: '#', pages: [14]},
                    { id: '-- Nedloddingssystem', link: '#', pages: [15]},
                    { id: '-- Leveringsline, kuleline og avkastnot', link: '#', pages: [16]},
                    { id: '-- Autonome vaskeroboter', link: '#', pages: [17]}
                ]
            },
            {
                id: 'Andre dokumenter',
                children: [
                    { id: 'LSG Etiske retningslinjer', link: '/etiske-retningslinjer.pdf', external: true },
                ]
            }
        ];
    }
    
    const [pages, setPages] = useState([]);
    const [pdfIsOpen, setPdfIsOpen] = useState(false);
    let openPages = (pages) => {
        console.log(pages);
        setPages(pages);
        setPdfIsOpen(true);
    };
    
    return (
        <div>
            <PdfPreview pages={pages} setPages={setPages} isOpen={pdfIsOpen} setIsOpen={setPdfIsOpen} />
            <Drawer variant="permanent" {...other}>
                <List disablePadding>
                    <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                        Ekstrautstyrsportal
                    </ListItem>
                    
                    {categories.map(({ id, children }) => (
                        <React.Fragment key={id}>
                            <ListItem className={classes.categoryHeader}>
                                <ListItemText
                                    classes={{
                                        primary: classes.categoryHeaderPrimary,
                                    }}
                                >
                                    {id}
                                </ListItemText>
                            </ListItem>
                            {children && children.map(({ id: childId, icon, active, link, external, pages }) => {
                                
                                return (
                                <ListItem
                                    key={childId}
                                    button
                                    className={clsx(classes.item, active && classes.itemActiveItem)}
                                    onClick={() => { 
                                        if(pages !== undefined && pages.length !== 0) {
                                            openPages(pages);
                                            return;
                                        }
                                        if(!external) { history.push(link) } else { window.open(link); }
                                    
                                    }}>
                                    {icon && 
                                        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                    }
                                    <ListItemText
                                        classes={{
                                            primary: classes.itemPrimary,
                                        }}
                                    >
                                        {childId}
                                    </ListItemText>
                                </ListItem>
                            )})}
    
                            <Divider className={classes.divider} />
                        </React.Fragment>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);