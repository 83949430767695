import {deleteRequest, getRequest, postRequest, putRequest} from "../helpers/NetworkHelper";

import {actionCreators as alertActions } from "./Alert";
import {begin, end, pendingTask} from "react-redux-spinner";

const requestStart = 'REQUEST_START';
const requestDone = 'REQUEST_DONE';
const receiveError = 'RECEIVE_ERROR';

const receiveUsers = 'RECEIVE_USERS';




const initialState = { users: [], error: null, isLoading: false };

export const actionCreators = {
    getUsersInOrganizationById: (orgnr) => async (dispatch, getState) => {
        dispatch({ type: requestStart, [ pendingTask ]: begin });
        getRequest(`User/GetUsersInOrganizationById/${orgnr}`, null, true, data => {
            if (data) {
                dispatch({ type: receiveUsers, [ pendingTask ]: end, data });
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getUsersInOrganization: () => async (dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        getRequest(`User/GetUsersInOrganization`, null, true, data => {
            if (data) {
                dispatch({ type: receiveUsers, [ pendingTask ]: end, data });
            }
        }).catch(error=> {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    addNewUserToOrganization: (organizationUserForSaving, callback) => async (dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        postRequest(`User/AddNewUserToOrganization`, organizationUserForSaving, true, true, (data) => {
            dispatch({ type: requestDone, [ pendingTask ]: end });
            dispatch(alertActions.showSuccess(data));
            dispatch(actionCreators.getUsersInOrganization());
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    deleteUserInOrganization: (organizationId, userId, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        deleteRequest(`User/DeleteUserInOrganization/${organizationId}/${userId}`, true, data=>{
            dispatch({type:requestDone, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    updateUser: (userForSaving, callback = null) => async (dispatch, getState) =>  {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        putRequest(`User/UpdateUser`, userForSaving, true, true,data=>{
            dispatch({type:requestDone, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {

        case receiveUsers:
            return {
                ...state,
                isLoading: false,
                users: action.data
            };
            
        case requestStart:
            return {
                ...state,
                isLoading: true
            };
        case requestDone:
            return {
                ...state,
                isLoading: false
            };
        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};