const dev = {
    api: {
        url: "//localhost:5001"
    }
};
const prod = {
    api: {
        url: "//eup.havbruksloggen.no"
    }
};
const config = process.env.REACT_APP_STAGE === 'production'
    ? prod
    : dev;
export default {
    //Common config values + evironmentspecific values
    ...config
};