import {deleteRequest, getRequest, postRequest, putRequest} from "../helpers/NetworkHelper";

import {actionCreators as alertActions } from "./Alert";
import {begin, end, pendingTask} from "react-redux-spinner";

const requestStart = 'REQUEST_START';
const receiveSuccess = 'RECEIVE_SUCCESS';
const receiveError = 'RECEIVE_ERROR';

const receiveOrganizationLookup = 'RECEIVE_ORG_LOOKUP';
const receiveOrganizations = 'RECEIVE_ORGANIZATIONS';
const receiveUsers = 'RECEIVE_USERS';

const initialState = { org: {}, organizations: [], users: [], error: null, isLoading: false };

export const actionCreators = {
    lookupOrganization: (orgnr) => async (dispatch, getState) => {
        dispatch({ type: requestStart, [ pendingTask ]: begin});
        getRequest(`Intranet/LookupOrganizationByOrgNr/${orgnr}`, null, true, data => {
            if (data) {
                dispatch({ type: receiveOrganizationLookup, [ pendingTask ]: end, data });
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    createOrganization: (organizationForSaving) => async (dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        postRequest(`Intranet/CreateOrganization`, organizationForSaving, true, true, data => {
            dispatch({type: receiveSuccess, [ pendingTask ]: end})
        }).catch(error=> {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    getOrganizations: () => async(dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        getRequest(`Intranet/GetOrganizations`, null, true, data => {
            if(data) {
                dispatch({type:receiveOrganizations, [ pendingTask ]: end, data});
            }
        }).catch(error=> {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    getUsers: () => async(dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        getRequest(`Intranet/GetUsers`, null, true, data => {
            if(data) {
                dispatch({type:receiveUsers, [ pendingTask ]: end, data});
            }
        }).catch(error=> {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    addNewUser: (UserForSaving, callback) => async (dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        postRequest(`User/AddNewUserToOrganization`, UserForSaving, true, true, (data) => {
            dispatch({ type: receiveSuccess, [ pendingTask ]: end });
            dispatch(alertActions.showSuccess(data));
            dispatch(actionCreators.getUsers());
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    updateUser: (userForSaving, callback = null) => async (dispatch, getState) =>  {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        putRequest(`Intranet/UpdateUser`, userForSaving, true, true,data=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    deleteUser: (userId, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        deleteRequest(`Intranet/DeleteUser/${userId}`, true, data=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            dispatch(actionCreators.getUsers());
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };
        
        case receiveSuccess:
            return {
                ...state,
                isLoading: false
            };
        
        case receiveUsers: 
            return {
                ...state,
                users: action.data
            };
            
        case receiveOrganizationLookup:
            return {
                ...state,
                isLoading: false,
                org: action.data
            };
            
        case receiveOrganizations:
            return {
                ...state,
                organizations: action.data
            };
            
            
        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };
        
        default:
            return state;
    }
};