import axios from "axios";
import config from "../config";
import {authHeader} from "./authHeader";
import history from './historyHelper';
const CancelToken = axios.CancelToken;

export function requestHeader(requiresAuthentication)
{
    const header = {
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin':'*'
    };
    if(requiresAuthentication) {
        const authorization = authHeader();
        Object.assign(header, authorization);
    }
    return {
        headers: header
    };
}

export function getRequest(apiEndpoint, data = null, requiresAuthentication = true, onFulfilled = null, cancelRequest = null) {
    let requestOptions = requestHeader(requiresAuthentication);
    if(data !== null) {
        Object.assign(requestOptions, {params: data});
    }
    if (cancelRequest) {
        Object.assign(requestOptions, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancelRequest = c;
            })
        });
    }
    return axios.get(`${config.api.url}/api/${apiEndpoint}`, requestOptions).then(handleSuccess).then(onFulfilled).catch(handleError);
}

export function postRequest(apiEndpoint, data = null, requiresAuthentication = true, stringify = true, onFulfilled = null) {
    let requestOptions = requestHeader(requiresAuthentication);
    const postData = stringify ? JSON.stringify(data) : data;
    return axios.post(`${config.api.url}/api/${apiEndpoint}`, postData, requestOptions).then(handleSuccess).then(onFulfilled).catch(handleError);
}

export function deleteRequest(apiEndpoint, requiresAuthentication = true, onFulfilled = null) {
    let requestOptions = requestHeader(requiresAuthentication);
    return axios.delete(`${config.api.url}/api/${apiEndpoint}`, requestOptions).then(handleSuccess).then(onFulfilled).catch(handleError);
}

export function patchRequest(apiEndpoint, data = null, requiresAuthentication = true, stringify = true, onFulfilled = null) {
    let requestOptions = requestHeader(requiresAuthentication);
    const patchData = stringify ? JSON.stringify(data) : data;

    return axios.patch(`${config.api.url}/api/${apiEndpoint}`, patchData, requestOptions).then(handleSuccess).then(onFulfilled).catch(handleError);
}

export function putRequest(apiEndpoint, data = null, requiresAuthentication = true, stringify = true, onFulfilled = null) {
    let requestOptions = requestHeader(requiresAuthentication);
    const putData = stringify ? JSON.stringify(data) : data;

    return axios.put(`${config.api.url}/api/${apiEndpoint}`, putData, requestOptions).then(handleSuccess).then(onFulfilled).catch(handleError);
}

export function handleSuccess(response) {
    const data = response.data;
    return JSON.parse(JSON.stringify(data));
}

export function handleError(error) {
    const data = error.response.data;
    const text = JSON.parse(JSON.stringify(data));
    if (error.response) {
        if (error.response.status === 401) {
            // auto logout if 401 response returned from api
            logout();
            window.location.reload(true);
        }

        return Promise.reject(text);
    } else {
        return text;
    }
}

export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    history.push("/login");
}