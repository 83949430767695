import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {actionCreators as deviationActions} from "../../../store/Deviation";



const styles = theme => ({
    paper: {
        overflow: 'hidden',
        margin: theme.spacing(3, 0)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '26px 26px',
    },
    sendBtn: {
        marginBottom: 20
    },
    deviationBtn: {
        "&:hover": {
            color: "#d80000"
        }
    }
});

class Deviation extends Component {
    
    render() {
        const formRef = createRef();
        const { classes, isOpen, deviationData, closeDeviationModal } = this.props;
        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={closeDeviationModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"xs"}
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">{deviationData && deviationData.deviationId !== undefined ? "Sjekk ut mangellapp" : "Opprett mangellapp"}
                    
                    </DialogTitle>
                    <DialogContent>
                        {deviationData && deviationData.deviationId !== undefined &&
                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    comment: '',
                                }}
                                validationSchema={Yup.object({
                                    comment: Yup.string()
                                        .required('Påkrevd.').min(15),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    setSubmitting(false);
                                    let deviationForClosing = {
                                        deviationId: deviationData.deviationId,
                                        closedComment: values.comment
                                    };
                                    this.props.deviationAction.closeDeviation(deviationForClosing, closeDeviationModal);
                                }}>
                                <Form className={classes.form}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            component={TextField}
                                            name="comment"
                                            label="Kommentar"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                    </FormControl>
                                    <DialogActions>
                                        <Button color="primary" onClick={closeDeviationModal}>
                                            Avbryt
                                        </Button>
                                        <Button color="primary" type={"submit"}>
                                            Lukk mangellapp
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </Formik>
                        }
                        {deviationData && deviationData.deviationId === undefined &&
                            <Formik
                                innerRef={formRef}
                                initialValues={{
                                    title: '',
                                    description: ''
                                }}
                                validationSchema={Yup.object({
                                    title: Yup.string()
                                        .required('Påkrevd.').min(5),
                                    description: Yup.string()
                                        .required('Påkrevd.').min(15),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    console.log(deviationData);
                                    setSubmitting(false);
                                    let deviationForSaving = {
                                        offerId: deviationData.offerId,
                                        deviationTypeId: deviationData.deviationTypeId,
                                        title: values.title,
                                        description: values.description
                                    };
                                    this.props.deviationAction.createDeviation(deviationForSaving, closeDeviationModal);
                                }}>
                                <Form className={classes.form}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            component={TextField}
                                            name="title"
                                            label="Tittel"
                                            variant="outlined"
                                            autoFocus
                                        />
                                    </FormControl>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            component={TextField}
                                            name="description"
                                            label="Beskrivelse"
                                            variant="outlined"
                                            multiline
                                            rows={5}
                                        />
                                    </FormControl>
                                    <DialogActions>
                                        <Button color="primary" onClick={closeDeviationModal}>
                                            Avbryt
                                        </Button>
                                        <Button color="primary" type={"submit"}>
                                            Opprett mangellapp
                                        </Button>
                                    </DialogActions>
                                </Form>
                            </Formik>
                        }
                    </DialogContent>
                    
                </Dialog>
            </div>
        );
    }
}

Deviation.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    deviationData: PropTypes.object,
};


function mapDispatchToProps(dispatch) {
    return {
        deviationAction: bindActionCreators(deviationActions, dispatch),
    };
}
export default connect(
    null, mapDispatchToProps
)(withStyles(styles)(Deviation));