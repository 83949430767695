import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import Grid from "@material-ui/core/Grid";
import React from "react";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";

class OrganizationStep extends React.Component {
    
    render() {
        const {classes} = this.props;
        return (
            <Formik
                enableReinitialize={true}
                initialValues={this.props.initialValue}
                validationSchema={Yup.object({
                    organizationNr: Yup.string()
                        /*.test('validate-org-nr', 'Skriv inn et gyldig organisasjonsnummer.',
                            function(value) {
                                let orgNr = value;
                                
                                if(typeof(orgNr) !== "string" ) return false;
                                
                                orgNr = orgNr.replace(' ', '').replace('-', '');
                                if (orgNr.length >= 9) {

                                    let controlNumber = 2,
                                        sumForMod = 0,
                                        i;

                                    for (i = orgNr.length - 2; i >= 0; --i) {
                                        sumForMod += orgNr.charAt(i) * controlNumber;
                                        if (++controlNumber > 7)
                                            controlNumber = 2;
                                    }
                                    let result = (11 - sumForMod % 11);

                                    return (result === 11 ? 0 : result) === parseInt(orgNr.charAt(orgNr.length - 1), 10);
                                    
                                }
                            }
                        )*/
                        .required('Påkrevd'),
                    name: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd'),
                    telephone: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd'),
                    streetAddress: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd'),
                    zipCode: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd'),
                    city: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd'),
                })}
                
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    this.props.organizationStepComplete(values);
                }}>
                <Form onChange={(event) => {
                    if (event.target.name === "organizationNr") {
                        let orgNr = event.target.value;
                        orgNr = orgNr.replace(' ', '').replace('-', '');
                        if (orgNr.length >= 9) {

                            let controlNumber = 2,
                                sumForMod = 0,
                                i;

                            for (i = orgNr.length - 2; i >= 0; --i) {
                                sumForMod += orgNr.charAt(i) * controlNumber;
                                if (++controlNumber > 7)
                                    controlNumber = 2;
                            }
                            let result = (11 - sumForMod % 11);

                            if ((result === 11 ? 0 : result) === parseInt(orgNr.charAt(orgNr.length - 1), 10) && orgNr !== this.props.lastValidOrgNr) {
                                this.props.lookUpOrganization(orgNr);
                            }
                        }
                    }
                }}>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            component={TextField}
                            name="organizationNr"
                            label="Organisasjonsnummer"
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="name"
                            label="Firmanavn"
                            variant="outlined"
                            component={TextField}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="telephone"
                            label="Telefon"
                            component={TextField}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="streetAddress"
                            label="Adresse"
                            component={TextField}
                            variant="outlined"
                        />
                    </FormControl>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormControl className={classes.formControl} fullWidth={true}>
                                <Field
                                    name="zipCode"
                                    label="Post.Nr"
                                    component={TextField}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <FormControl className={classes.formControl} fullWidth={true}>
                                <Field
                                    name="city"
                                    label="By"
                                    component={TextField}
                                    variant="outlined"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    {this.props.wizardActions}
                </Form>
            </Formik>
        );
    }
}

export default OrganizationStep;