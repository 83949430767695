import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const AddEditEquipmentCategory = (
    {
        classes, 
        equipmentCategoryForSaving, 
        handleEquipmentCategoryChange, 
        isAddingEquipmentCategory, 
        cancelAddEditEquipmentCategory, 
        handleAddEditEquipmentCategory
    }) => {
    return (
        <div>
            <Dialog
                open={isAddingEquipmentCategory}
                onClose={cancelAddEditEquipmentCategory}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Legg til ny utstyrskategori"}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <TextField
                            name="category"
                            label="Tittel"
                            value={equipmentCategoryForSaving.category || ''}
                            onChange={handleEquipmentCategoryChange('category')}
                            variant="outlined"
                            required
                        />
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={(equipmentCategoryForSaving?.active == null ? true : equipmentCategoryForSaving.active)}
                                onChange={handleEquipmentCategoryChange('active')}
                                color="primary"
                            />
                        }
                        label="Aktiv?"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={cancelAddEditEquipmentCategory} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleAddEditEquipmentCategory} color="primary" autoFocus>
                        {!equipmentCategoryForSaving.equipmentCategoryId ? "Legg til" : "Rediger"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddEditEquipmentCategory;