import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {FilePond, registerPlugin} from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import config from "../../config";
import {authHeader} from "../../helpers/authHeader";
import axios from 'axios';

import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize,FilePondPluginImageEdit);

const styles = theme => ({});

class FileUploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: (this.props.initialFiles && this.props.initialFiles.map(function(initialFile) { return { source: initialFile.viewToken, options: { type: 'local' }}}))
        }

    }

    updateFileStatuses = () => {
        this.props.isUploading && this.props.isUploading(this.pond.getFiles().filter(x=>x.status !== 5).length !== 0)
    };
    
    removeAllFiles = () => {
        this.pond.removeFiles();
    };

    render() {
        const {addedFiles, removedFiles, onFilesChanged} = this.props;
        return (
            <FilePond
                ref={ref => (this.pond = ref)}
                files={this.state.files}
                allowMultiple={true}
                maxFileSize={this.props.maxFileSize || null}
                acceptedFileTypes={this.props.acceptedFileTypes || []}
                beforeRemoveFile={function(item) {if(!window.confirm("Er du sikker?")) return false; }}
                allowImageEdit={true}
                allowImagePreview={this.props.imagePreview != null ? this.props.imagePreview : true}
                server={
                    {
                        url: config.api.url,
                        process: {
                            url: '/api/file/Upload',
                            method: 'POST',
                            withCredentials: false,
                            headers: authHeader(),
                            timeout: 7000,
                        },
                        load: '/api/file/',
                        revert: null,
                        remove: (source, load, error) => {
                            if(removedFiles === null) {

                                const authorization = authHeader();
                                const header = {
                                    "Content-Type": "application/json",
                                    'Access-Control-Allow-Origin': '*'
                                };
                                Object.assign(header, authorization);
                                const requestOptions = {
                                    headers: header
                                };

                                axios.delete(`${config.api.url}/api/file/${source}`, requestOptions).then(() => {
                                    load();
                                }).catch(() => {
                                    error("Noe gikk galt under sletting av fil.");
                                });
                            } else {
                                removedFiles.push(source);
                                load();
                            }

                        },

                        imagePreviewMaxHeight: 80,

                    }}
                onprocessfile={(error, file) => {
                    if (error === null) {
                        addedFiles.push({clientId: file.id, serverId: file.serverId});
                    }
                    onFilesChanged && onFilesChanged(this.pond.getFiles());
                }}
                onprocessfiles={() => {
                    this.updateFileStatuses();
                }}

                onremovefile={(error, file) => {
                    console.log(file, addedFiles[0]);
                    let item = addedFiles.find(item=>item.clientId === file.id);
                    let index = addedFiles.indexOf(item);
                    addedFiles.splice(index,1);
                }}

                onupdatefiles={items => {
                    this.setState({
                        files: items.map(fileItem => fileItem.file)
                    });

                    onFilesChanged && onFilesChanged(items);
                    this.updateFileStatuses();
                }}
                onactivatefile={file => {
                    this.props.selectedFile && this.props.selectedFile(file);
                }}
            />

        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.initialFiles !== this.props.initialFiles) {
            this.setState({files: (this.props.initialFiles && this.props.initialFiles.map(function(initialFile) { return { source: initialFile.viewToken, options: { type: 'local' }}})) || [] });
        }

    }

    componentDidMount() {
    }


}

FileUploader.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
    return {
    }
}

function mapStateToProps(state) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FileUploader));