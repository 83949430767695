import {getRequest, postRequest} from "../helpers/NetworkHelper";
import {actionCreators as alertActions } from "./Alert";
import {begin, pendingTask, end} from "react-redux-spinner";

const requestStart = 'REQUEST_START';
const receiveError = 'RECEIVE_ERROR';
const receiveSuccess = 'RECEIVE_SUCCESS';

const initialState = { error: null, isLoading: false };

export const actionCreators = {
    createDeviation: (deviationForSaving, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Deviation/CreateDeviation`, deviationForSaving, true, true,()=>{
            
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback(null, true);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    }, 
    closeDeviation: (deviationForClosing, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Deviation/CloseDeviation`, deviationForClosing, true, true,()=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback(null, true);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };
            
        case receiveSuccess:
            return {
                ...state,
                isLoading: false
            };

        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};