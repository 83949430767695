import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import history from "../../helpers/historyHelper";
import {Spinner} from "react-redux-spinner";

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    secondaryBar: {
        zIndex: 0,
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
    appBar: {
        backgroundColor: "#18202c!important",
        color: "white",
        "&a": {
            color: "white"
        }
    },
    appBarContent: {
        maxWidth: "1250px",
    },
    userLink: {
        color: "#FFF"
    }
});



function Header(props) {
    let user = JSON.parse(localStorage.getItem('user'));
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { classes, onDrawerToggle } = props;

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleLogout = () => {
        localStorage.removeItem("user");
        handleClose();
        history.push("/");
    };
    
    return (
        <React.Fragment>
            <AppBar id="AppBar" className={classes.appBar} position="sticky" elevation={0}>
                <Toolbar className={classes.appBarContent}>
                    <Grid container spacing={1} alignItems="center">
                        <Hidden smUp>
                            <Grid item>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={onDrawerToggle}
                                    className={classes.menuButton}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Grid>
                        </Hidden>
                        <Grid item xs>
                            <Typography color="inherit" variant="h5" component="h1">
                                
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Link className={classes.userLink} href="#" onClick={handleClick}>
                                {user && user.fullname}
                            </Link>
                            <Menu 
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}>
                                <MenuItem onClick={handleLogout}>Logg ut</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Spinner  config={{parent: "#AppBar"}}/>
            {/*<AppBar
                component="div"
                className={classes.secondaryBar}
                color="primary"
                position="static"
                elevation={0}>
                <Tabs value={0} textColor="inherit">
                    <Tab textColor="inherit" label="Users" />
                    <Tab textColor="inherit" label="Sign-in method" />
                    <Tab textColor="inherit" label="Templates" />
                    <Tab textColor="inherit" label="Usage" />
                </Tabs>
            </AppBar>*/}
        </React.Fragment>
    );
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    onDrawerToggle: PropTypes.func.isRequired,
};

export default withStyles(styles)(Header);