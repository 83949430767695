import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField, Select} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(() => createStyles({
    formControl: {
        margin: "10px 0",
    },
}));

const EditUser = ({updateUser, userData, isOpen, setIsOpen}) => {
    
    const classes = useStyles();
    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xs"}
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title">
                Redigere bruker
            </DialogTitle>
            <DialogContent>
                <Formik
                    enableReinitialize={true}
                    initialValues={{
                        firstname: (userData?.firstName || ""), 
                        lastname: (userData?.lastName || ""), 
                        email: (userData?.email || ""), 
                        cellPhone: (userData?.cellPhone || ""), 
                        userRole: (userData?.userRole || "0")
                    }}
                    validationSchema={Yup.object({
                        firstname: Yup.string()
                            .required('Påkrevd.'),
                        lastname: Yup.string()
                            .max(256, 'Må være mindre eller lik 256 karakterer.')
                            .required('Påkrevd.'),
                        email: Yup.string()
                            .max(256, 'Må være mindre eller lik 256 karakterer.')
                            .email('Må være en gyldig epost.')
                            .required('Påkrevd.'),
                        cellPhone: Yup.string()
                            .max(256, 'Må være mindre eller lik 256 karakterer.')
                            .required('Påkrevd.'),
                        userRole: Yup.number()
                            .required()
                    })}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(false);
                        updateUser({
                            UserId: userData.userId,
                            Email: values.email,
                            FirstName: values.firstname,
                            LastName: values.lastname,
                            CellPhone: values.cellPhone,
                            userRole: values.userRole
                        }, () => setIsOpen(false));
                    }}>
                    <Form>
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <Field
                                component={TextField}
                                name="firstname"
                                label="Fornavn"
                                variant="outlined"
                            />
                        </FormControl>
        
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <Field
                                name="lastname"
                                label="Etternavn"
                                variant="outlined"
                                component={TextField}
                            />
                        </FormControl>
        
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <Field
                                name="cellPhone"
                                label="Mobilnummer"
                                variant="outlined"
                                component={TextField}
                            />
                        </FormControl>
        
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <Field
                                name="email"
                                label="Epost"
                                component={TextField}
                                variant="outlined"
                            />
                        </FormControl>
        
                        <FormControl className={classes.formControl} fullWidth={true}>
                            <Field
                                as="select" 
                                name="userRole"
                                label="Rolle"
                                select
                                component={TextField}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            >
                                <MenuItem value="0">Normal</MenuItem>
                                <MenuItem value="1">Admin</MenuItem>
                                <MenuItem value="2">SuperAdmin</MenuItem>
                            </Field>
                        </FormControl>
                        <DialogActions>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.completeButton}
                        >
                            Oppdater
                        </Button>
                        </DialogActions>
                    </Form>
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default EditUser;