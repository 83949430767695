import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as offerActions} from "../../../../store/Offer";

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = theme => ({
    checkListItem: {
        lineHeight: "40px",
        padding: "12px 12px",
        borderBottom: "1px solid lightgray",
        fontWeight: "normal",
        fontSize: "14px",
        "&:last-child": {
            borderBottom: "none"
        },
        "&:hover": {
            background: "rgba(240,240,240,0.4)"
        }
    },
    toggleButton: {
        "&.Mui-selected&:nth-child(1)": {
            background: "lightgreen",
            color: "white"
        },
        "&.Mui-selected&:nth-child(2)": {
            background: "lightcoral",
            color: "white"
        },
        "&.Mui-selected&:nth-child(3)": {
            borderLeft: "1px solid lightgray",
            background: "lightyellow",
            color: "black"
        }
        
    }
});

class Checklist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkListData: [],
        };
        
    }
    
    handleChangeCheckListItemData = (checkListItemDataId) => (event, newValue) => {
        let checklist = this.state.checkListData.find(function(x){return x.checkListTemplateId === 1});
        let checklistItem = checklist.checkListItemData.find(function(x){return x.checkListItemDataId === checkListItemDataId});

        checklistItem.intValue = parseInt(newValue);

        this.props.offerActions.updateCheckListItemData(checklistItem);
        console.log(this.props.offer.offer, event, newValue);
    };
    
    render() {
        const { disableFields, classes } = this.props;
        const { checkListData } = this.state;
        return (
            <div>
                
            {checkListData && checkListData.find(function(x){return x.checkListTemplateId === 1}) &&
                checkListData.find(function(x){return x.checkListTemplateId === 1}).checkListItemData.map((item)=> (
                    <Grid container key={item.checkListItemDataId} className={classes.checkListItem}>
                        <Grid item xs={9}>
                            {item.title}
                        </Grid>
                        <Grid item xs={3} style={{textAlign: 'right'}}>
                            <ToggleButtonGroup size="small" value={1} exclusive onChange={this.handleChangeCheckListItemData(item.checkListItemDataId)}>
                                <ToggleButton className={classes.toggleButton} key={1} value="1" selected={(item.intValue || null) === 1} disabled={disableFields}>
                                    Ja
                                </ToggleButton>
                                <ToggleButton className={classes.toggleButton} key={2} value="2" selected={(item.intValue || null) === 2} disabled={disableFields}>
                                    Nei
                                </ToggleButton>
                                <ToggleButton className={classes.toggleButton} key={3} value="3" selected={(item.intValue || null) === 3} disabled={disableFields}>
                                    Ikke relevant
                                </ToggleButton>
                            </ToggleButtonGroup>
                            
                        </Grid>
                    </Grid>
                ))
            }
            </div>
        );
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.checkListData
            !== this.props.checkListData) {
            this.setState({ checkListData: this.props.checkListData });
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        offerActions: bindActionCreators(offerActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        offer: state.offer
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(Checklist));