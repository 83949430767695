import {getRequest, postRequest} from "../helpers/NetworkHelper";
import {actionCreators as alertActions } from "./Alert";
import {begin, pendingTask, end} from "react-redux-spinner";

const requestStart = 'REQUEST_START';
const receiveError = 'RECEIVE_ERROR';
const receiveSuccess = 'RECEIVE_SUCCESS';

const receiveCategories = 'RECEIVE_CATEGORIES';

const receiveEquipmentUpdateSuccess = 'RECEIVE_EQUIPMENT_UPDATE_SUCCESS';

const initialState = { categories: [], error: null, isLoading: false };

export const actionCreators = {
    getCategories: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Equipment/GetCategories`, null, true, data => {
            if (data) {
                dispatch({type:receiveCategories, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});  
        });
    },
    createOrUpdateCategory: (categoryForSaving, callback = null) => async (dispatch, getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        postRequest(`Equipment/CreateOrUpdateCategory`, categoryForSaving, true, true, data => {
            dispatch({type:receiveSuccess, [ pendingTask ]: end, data});
            console.log(this);
            dispatch(actionCreators.getCategories());
            callback && callback();
        }).catch(error=> {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    updateEquipment: (equipmentForSaving) => async(dispatch,getState) => {
        dispatch({type:requestStart, [ pendingTask ]: begin});
        postRequest(`Equipment/UpdateEquipment`, equipmentForSaving, true, true,data=>{
            dispatch({type:receiveEquipmentUpdateSuccess, [ pendingTask ]: end});
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };

        case receiveCategories:
            return {
                ...state,
                isLoading: false,
                categories: action.data
            };

        case receiveEquipmentUpdateSuccess:
            return {
                ...state,
                isLoading: false
            };
        
        case receiveSuccess: 
            return {
                ...state,
                isLoading: false
            };

        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};