import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";

class OrganizationStep extends React.Component {
    
    render() {
        const {classes} = this.props;
        
        return (
            <Formik
                initialValues={this.props.initialValue}
                validationSchema={Yup.object({
                    firstname: Yup.string()
                        .required('Påkrevd.'),
                    lastname: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd.'),
                    email: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .email('Må være en gyldig epost.')
                        .required('Påkrevd.'),
                    cellPhone: Yup.string()
                        .max(256, 'Må være mindre eller lik 256 karakterer.')
                        .required('Påkrevd.'),
                    password1: Yup.string()
                        .max(56, 'Må være mindre eller lik 56 karakterer.')
                        .required('Påkrevd.')
                        .matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                            "Må inneholde 8 karakterer, stor bokstav, små bokstav og tall"
                        ),
                    password2: Yup.string()
                        .oneOf([Yup.ref('password1'), null], 'Passordbekreftelsen samsvarer ikke med passordet.')
                        .max(56, 'Må være mindre eller lik 56 karakterer.')
                        .required('Påkrevd.'),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    this.props.useraccountStepComplete(values);
                }}>
                <Form>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            component={TextField}
                            name="firstname"
                            label="Fornavn"
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="lastname"
                            label="Etternavn"
                            variant="outlined"
                            component={TextField}
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="cellPhone"
                            label="Mobilnummer"
                            variant="outlined"
                            component={TextField}
                        />
                    </FormControl>
                    
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            name="email"
                            label="Epost"
                            component={TextField}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            type="password"
                            name="password1"
                            label="Passord"
                            component={TextField}
                            variant="outlined"
                        />
                    </FormControl>
                    
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            type="password"
                            name="password2"
                            label="Passord bekreftelse"
                            component={TextField}
                            variant="outlined"
                        />
                    </FormControl>
                    {this.props.wizardActions}
                </Form>
            </Formik>
        );
    }
}

export default OrganizationStep;