import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import React from "react";


const DeviationNotice = ({deviations, deviationType, openCloseDeviationModal, disableFields}) => {
    return ((deviations || []).filter(x => x.deviationTypeId === deviationType && x.deviationStatusId === 1).length === 0) ? null : (
        <>
        <h3>
            Mangellapper
        </h3>
        {(deviations || []).filter(x => x.deviationTypeId === deviationType && x.deviationStatusId === 1).map((deviation) => {
            return (
                <div key={deviation.deviationId}>
                    <Alert
                        severity="error"
                        action={!disableFields && (
                            <Button color="inherit" size="small" onClick={() => openCloseDeviationModal(deviation.deviationId)}>
                                Sjekk ut
                            </Button>
                        )}>
                        <AlertTitle>
                            {deviation.title}
                        </AlertTitle>
                        {deviation.description}
                    </Alert>
                    <br/>
                </div>
            );
        })}
    </>
    )
};

export default DeviationNotice;