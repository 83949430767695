import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import ErrorIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Collapse from "@material-ui/core/Collapse";
import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Checklist from "../../parts/offer/Checklist";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useState} from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

const useStyles = makeStyles((theme) => createStyles({
    checkListItem: {
        lineHeight: "40px",
        padding: "12px 12px",
        borderBottom: "1px solid lightgray",
        fontWeight: "normal",
        fontSize: "14px",
        "&:last-child": {
            borderBottom: "none"
        },
        "&:hover": {
            background: "rgba(240,240,240,0.4)"
        }
    },
    toggleButton: {
        "&.Mui-selected&:nth-child(1)": {
            background: "lightgreen",
            color: "white"
        },
        "&.Mui-selected&:nth-child(2)": {
            background: "lightcoral",
            color: "white"
        },
        "&.Mui-selected&:nth-child(3)": {
            borderLeft: "1px solid lightgray",
            background: "lightyellow",
            color: "black"
        }

    }
}));

const ViewCompanyDeclaration = ({isOpen, setIsOpen, checkListItems}) => {
    const classes = useStyles();

    return (
        <Dialog open={isOpen} onClose={()=>setIsOpen(false)} maxWidth={false} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Leverandørerklæring</DialogTitle>
            <DialogContent>
                <div style={{width: 960}}>
                    {(checkListItems || []).map((item, index) => (
                        <Grid container key={index} className={classes.checkListItem}>
                            <Grid item xs={9}>
                                {item.title}
                            </Grid>
                            <Grid item xs={3} style={{textAlign: 'right'}}>
                                <ToggleButtonGroup size="small" value={1} exclusive>
                                    <ToggleButton className={classes.toggleButton} key={1} value="1" selected={(item.intValue || null) === 1} disabled={true}>
                                        Ja
                                    </ToggleButton>
                                    <ToggleButton className={classes.toggleButton} key={2} value="2" selected={(item.intValue || null) === 2} disabled={true}>
                                        Nei
                                    </ToggleButton>
                                    <ToggleButton className={classes.toggleButton} key={3} value="3" selected={(item.intValue || null) === 3} disabled={true}>
                                        Ikke relevant
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                        </Grid>
                    ))}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>setIsOpen(false)} color="primary">
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default (ViewCompanyDeclaration);