import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {actionCreators} from "../../store/Authentication";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import Link from "@material-ui/core/Link";
import historyHelper from "../../helpers/historyHelper";
import Box from "@material-ui/core/Box";

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/login-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
});

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            submitted: false,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { email } = this.state;
        this.setState({ submitted: true });

        if (email) {
            this.props.forgot(email);
        }

    };

    render()
    {
        const {classes} = this.props;
        const {forgotPasswordToken} = this.props.match.params;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Glemt passord
                        </Typography>
                        {forgotPasswordToken === undefined &&
                            <Formik
                                initialValues={{
                                    email: ''
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .email('Må være en gyldig epost.')
                                        .required('Påkrevd.'),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    setSubmitting(false);
                                    this.props.forgotPassword(values.email);
                                }}>
                                <Form className={classes.form}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            component={TextField}
                                            name="email"
                                            label="E-post"
                                            autoFocus
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}>
                                        Glemt passord
                                    </Button>
                                </Form>
                            </Formik>
                        }
                        {forgotPasswordToken !== undefined &&
                            <Formik
                                enableReinitialize={true}
                                initialValues={{
                                    email: this.props.forgotPasswordEmail,
                                    password1: '',
                                    password2: ''
                                }}
                                validationSchema={Yup.object({
                                    email: Yup.string()
                                        .email('Må være en gyldig epost.')
                                        .required('Påkrevd.'),
                                    password1: Yup.string()
                                        .max(56, 'Må være mindre eller lik 56 karakterer.')
                                        .required('Påkrevd.')
                                        .matches(
                                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                            "Må inneholde 8 karakterer, stor bokstav, små bokstav og tall"
                                        ),
                                    password2: Yup.string()
                                        .oneOf([Yup.ref('password1'), null], 'Passordbekreftelsen samsvarer ikke med passordet.')
                                        .max(56, 'Må være mindre eller lik 56 karakterer.')
                                        .required('Påkrevd.'),
                                })}
                                onSubmit={(values, {setSubmitting}) => {
                                    setSubmitting(false);
                                    this.props.completeForgotPassword(forgotPasswordToken, values.password1);
                                }}>
                                <Form className={classes.form}>
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            component={TextField}
                                            name="email"
                                            label="E-post"
                                            autoFocus
                                            variant="outlined"
                                            disabled
                                        />
                                    </FormControl>

                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            type="password"
                                            name="password1"
                                            label="Passord"
                                            component={TextField}
                                            variant="outlined"
                                        />
                                    </FormControl>
    
                                    <FormControl className={classes.formControl} fullWidth={true}>
                                        <Field
                                            margin="normal"
                                            type="password"
                                            name="password2"
                                            label="Passord bekreftelse"
                                            component={TextField}
                                            variant="outlined"
                                        />
                                    </FormControl>
                                    
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        className={classes.submit}>
                                        Endre passord
                                    </Button>
                                </Form>
                            </Formik>
                            
                        }
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" onClick={() => historyHelper.push('/')} variant="body2">
                                    Gå tilbake
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>

                        </Box>
                    </div>
                </Grid>
            </Grid>
        );
    }
    
    componentDidMount() {
        const {forgotPasswordToken} = this.props.match.params;
        console.log(forgotPasswordToken);
        if(forgotPasswordToken !== undefined) {
            this.props.verifyForgotPasswordToken(forgotPasswordToken);
        }
    }
}

export default connect(
    state => state.authentication,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(ForgotPassword));