import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import {bindActionCreators} from "redux";
import {actionCreators as userActions} from "../../../store/User";
import AddUser from "../Modal/AddUser";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        userActions: bindActionCreators(userActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}

const Users = ({user,userActions}) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        userActions.getUsersInOrganization();
    }, []);
    
    const deleteUser = (organizationId, userId) => {
        if(!window.confirm("Er du sikker?")) return;
        userActions.deleteUserInOrganization(organizationId, userId, reloadUsers);
    };
    
    const reloadUsers = () => {
        userActions.getUsersInOrganization();
    };
    
    let userObj = JSON.parse(localStorage.getItem('user'));
    console.log(userObj);
    return (
        <div>
            <AddUser isOpen={isOpen} setIsOpen={(value) => setIsOpen(value)} classes={classes} addUser={userActions.addNewUserToOrganization}/>
            <h1>Brukere</h1>
            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" className={classes.newUser}
                                        onClick={() => setIsOpen(true)}>
                                    Legg til bruker
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    {user.users.length !== 0 &&
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell algin="left">Id</TableCell>
                                <TableCell align="left">Navn</TableCell>
                                <TableCell align="left">Epost</TableCell>
                                <TableCell align="left">Tlf</TableCell>
                                <TableCell align="left">Registrert</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {user.users.map(row => (
                                <TableRow className={classes.itemRow} key={row.userId}>
                                    <TableCell align="left">{row.userId}</TableCell>
                                    <TableCell align="left">{row.fullname}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">{row.cellPhone}</TableCell>
                                    <TableCell align="left"><Moment>{row.RegisteredDateTime}</Moment></TableCell>
                                    <TableCell aling="right">
                                        {row.userId !== userObj.userId &&
                                            <IconButton aria-label="delete" onClick={() => deleteUser(row.organizationId, row.userId)}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }

                    {user.users.length === 0 &&
                    <Typography color="textSecondary" align="center">
                        Ingen tilbud tilgjengelig
                    </Typography>
                    }
                </div>
            </Paper>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
