import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Layout from './components/Template/Layout';
import Home from './components/Pages/Home';
import Login from "./components/Pages/Login";
import {PrivateRoute} from "./helpers/PrivateRoute";
import NoLayout from "./components/Template/NoLayout";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Organizations from "./components/Pages/Intranet/Organizations";
import Registration from "./components/Pages/Registration";
import UserRegistration from "./components/Pages/UserRegistration";
import EquipmentCategories from "./components/Pages/Intranet/EquipmentCategories";
import Checklists from "./components/Pages/Intranet/Checklists";
import Offer from "./components/Pages/Offer";
import Cases from "./components/Pages/Cases";
import ForgotPassword from "./components/Pages/ForgotPassword";
import Moment from 'react-moment';
import DismissedCases from "./components/Pages/DismissedCases";
import ApprovedCases from "./components/Pages/ApprovedCases";
import Users from "./components/Pages/Organization/Users";
import IntranetUsers from "./components/Pages/Intranet/Users";
import OrganizationDeclarations from "./components/Pages/Organization/OrganizationDeclarations";
import MyCases from "./components/Pages/MyCases";
import IncomingCases from "./components/Pages/IncomingCases";
import CasesForApproval from "./components/Pages/CasesForApproval";
import OfferEvaluation from "./components/Pages/OfferEvaluation";
import AllCases from "./components/Pages/AllCases";
Moment.globalFormat = 'YYYY-MM-DD HH:mm';
Moment.globalLocal = true;

toast.configure();

export default () => (
    <div>
        <Switch>
            <Route exact path='/' component={LoginContainer} />
            <Route exact path='/login' component={LoginContainer} />
            <Route exact path='/login/:registrationToken/' component={LoginContainer} />
            <Route exact path='/registration' component={LoginContainer} />
            <Route exact path='/Registration/:registrationToken/' component={LoginContainer} />
            <Route exact path='/Forgot' component={LoginContainer} />
            <Route exact path='/Forgot/:forgotPasswordToken/' component={LoginContainer} />
            <PrivateRoute exact path='/Offer/Evaluation/:offerId/:evaluationId' component={NoLayoutContainer} />
            <PrivateRoute path='*' component={DefaultContainer} />
        </Switch>
    </div>
);


const LoginContainer = () => (
    <NoLayout>
        <ToastContainer/>
        <Route exact path='/' render={() => <Redirect to="/login"/>}/>
        <Route exact path='/Login' component={Login} />
        <Route exact path='/Login/:registrationToken/' component={Login} />
        <Route exact path='/Registration' component={Registration} />
        <Route exact path='/Registration/:registrationToken/' component={UserRegistration} />
        <Route exact path='/Forgot' component={ForgotPassword} />
        <Route exact path='/Forgot/:forgotPasswordToken/' component={ForgotPassword} />
    </NoLayout>
);

const NoLayoutContainer = () => (
    <NoLayout>
        <ToastContainer/>
        <PrivateRoute exact path='/Offer/Evaluation/:offerId/:evaluationId' component={OfferEvaluation} />
    </NoLayout>
);

const DefaultContainer = () => (
    <Layout>
        <ToastContainer/>
        <PrivateRoute exact path='/Dashboard' component={Home} />
        <PrivateRoute exact path='/intranet/suppliers' component={Organizations} />
        <PrivateRoute exact path='/intranet/equipmentCategories' component={EquipmentCategories} />
        <PrivateRoute exact path='/intranet/checklists' component={Checklists} />
        <PrivateRoute exact path='/intranet/users' component={IntranetUsers} />
        <PrivateRoute exact path='/Organization/Users' component={Users} />
        <PrivateRoute exact path='/Organization/declarations' component={OrganizationDeclarations} />
        <PrivateRoute exact path='/Home' component={Home} />
        <PrivateRoute exact path='/AllCases' component={AllCases} />
        <PrivateRoute exact path='/Cases' component={Cases} />
        <PrivateRoute exact path='/MyCases' component={MyCases} />
        <PrivateRoute exact path='/IncomingCases' component={IncomingCases} />
        <PrivateRoute exact path='/ApprovedCases' component={ApprovedCases} />
        <PrivateRoute exact path='/CasesForApproval' component={CasesForApproval} />
        <PrivateRoute exact path='/DismissedCases' component={DismissedCases} />
        <PrivateRoute exact path='/Offer/:offerId' component={Offer} />
    </Layout>
);
