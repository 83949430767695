import {deleteRequest, getRequest, postRequest} from "../helpers/NetworkHelper";
import {actionCreators as alertActions } from "./Alert";
import Offer from "../components/Pages/Offer";
import { pendingTask, begin, end } from 'react-redux-spinner';

const requestStart = 'REQUEST_START';
const receiveError = 'RECEIVE_ERROR';
const receiveSuccess = 'RECEIVE_SUCCESS';

const receiveOffers = 'RECEIVE_OFFERS';
const receiveOffer = 'RECEIVE_OFFER';

const receiveCheckListItemData = 'RECEIVE_CHECK_LIST_ITEM_DATA';
const receiveUpdateOfferSuccess = 'RECEIVE_UPDATE_OFFER_SUCCESS';

const receiveSaveFilesSuccess = 'RECEIVE_SAVE_FILES_SUCCESS';
const receiveFiles = 'RECEIVE_FILES';
const receiveFileCategories = 'RECEIVE_FILE_CATEGORIES';
const receiveCaseWorkers = 'RECEIVE_CASE_WORKERS';
const receiveAssignCaseWorkerSuccess = 'RECEIVE_ASSIGN_CASE_WORKER';

const receiveOfferStatusOptions = 'RECEIVE_OFFER_STATUS_OPTIONS';

const receiveCreateOfferEvaluationSuccess = 'RECEIVE_CREATE_OFFER_EVALUATION_SUCCESS';

const receiveOfferEvaluation = 'RECEIVE_OFFER_EVALUATION';

const initialState = { offers: [], offer: {}, evaluation: {}, files: [], fileCategories: [], caseWorkers: [], offerStatusOptions: [], error: null, isLoading: false };

export const actionCreators = {
    getOffers: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetOffers`, null, true, data => {
            if (data) {
                dispatch({type:receiveOffers, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getOffer: (offerId) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetOffer/${offerId}`, null, true, data => {
            if (data) {
                dispatch({type:receiveOffer, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getOfferStatusOptions: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetOfferStatusOptions/`, null, true, data => {
            if (data) {
                dispatch({type:receiveOfferStatusOptions, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    updateOfferStatus: (offerStatusForUpdate, callback = null) => async(dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/UpdateOfferStatus`, offerStatusForUpdate, true, true,()=>{
           
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback(null,true);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getFiles: (offerId) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetFiles/${offerId}`, null, true, data => {
            if (data) {
                dispatch({type:receiveFiles, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getFileCategories: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`File/GetFileCategories`, null, true, data => {
            if (data) {
                dispatch({type:receiveFileCategories, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getCaseWorkers: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetCaseWorkers`, null, true, data => {
            if (data) {
                dispatch({type:receiveCaseWorkers, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    assignCaseWorker: (assignCaseworkerForSaving, callback) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/AssignCaseWorker`, assignCaseworkerForSaving, true, true,data=>{
            dispatch({type:receiveAssignCaseWorkerSuccess, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    createOffer: (callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/CreateOffer`, null, true, false,data=>{
            if(data) {
                dispatch({type:receiveOffer, [ pendingTask ]: end});
                callback && callback(data.offerId);
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    completeOffer: (offerId, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/CompleteOffer/${offerId}`, null, true, false,()=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback(offerId);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },

    updateOffer: (offerForSaving, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        delete offerForSaving.equipments;
        delete offerForSaving.checkListData;
        delete offerForSaving.registeredByUser;
        delete offerForSaving.organization;
        postRequest(`Offer/UpdateOffer`, offerForSaving, true, false,data=>{
            dispatch({type:receiveUpdateOfferSuccess, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    updateCheckListItemData: (checkListItemDataForSaving, callback=null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/UpdateCheckListItemData`, checkListItemDataForSaving, true, true,data=>{
            dispatch({type:receiveCheckListItemData, [ pendingTask ]: end});
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    saveFiles: (offerId, fileCategoryId, tempFiles, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/SaveFiles`, {OfferId: offerId, FileCategoryId: fileCategoryId, TempFiles: tempFiles}, true, true,data=>{
            dispatch({type:receiveSaveFilesSuccess, [ pendingTask ]: end});
            callback && callback()
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    deleteFile: (viewToken, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        deleteRequest(`Offer/DeleteFile/${viewToken}`, true, data=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    }, 
    deleteDraft: (offerId, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        deleteRequest(`Offer/DeleteDraft/${offerId}`, true, data=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback();
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    createNewEvaluation: (offerId, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Offer/CreateNewEvaluation/${offerId}`, null, true, true,data=>{
            dispatch({type:receiveCreateOfferEvaluationSuccess, [ pendingTask ]: end, data});
            callback && callback(data);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        })
    },
    getEvaluation: (offerEvaluationId) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Offer/GetOfferEvaluation/${offerEvaluationId}`, null, true, data => {
            if (data) {
                dispatch({type:receiveOfferEvaluation, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };

        case receiveOffers:
            return {
                ...state,
                isLoading: false,
                offers: action.data
            };
            
        case receiveOfferStatusOptions:
            return {
                ...state,
                isLoading: false,
                offerStatusOptions: action.data,
            };
        case receiveCheckListItemData:
            return {
                ...state,
                isLoading: false
            };
 
        case receiveOffer:
            return {
                ...state,
                isLoading: false,
                offer: action.data
            };
            
        case receiveFiles:
            return{
                ...state,
                isLoading:false,
                files: action.data
            };  
            
        case receiveFileCategories:
            return{
                ...state,
                isLoading:false,
                fileCategories: action.data
            };
            
        case receiveCaseWorkers:
            return {
                ...state,
                isLoading: false,
                caseWorkers: action.data
            };
            
        case receiveAssignCaseWorkerSuccess:
            return {
                ...state,
                isLoading: false,
            };

        case receiveUpdateOfferSuccess:
            return {
                ...state,
                isLoading: false
            };
            
        case receiveSaveFilesSuccess:
            return {
                ...state,
                isLoading: false
            };
            
        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case receiveSuccess:
            return {
                ...state,
                isLoading: false,
            };

        case receiveCreateOfferEvaluationSuccess:
            return {
                ...state,
                isLoading: false,
                offer: {...state.offer, offerEvaluations: [...state.offer.offerEvaluations, action.data]}
            };
            
        case receiveOfferEvaluation: 
            return {
                ...state,
                isLoading: false,
                evaluation: action.data
            };
        default:
            return state;
    }
};