import React, {Component, createRef} from 'react';
import {connect} from 'react-redux';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField, Select} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {withStyles} from "@material-ui/core";
import PropTypes from 'prop-types';
import {bindActionCreators} from "redux";
import {actionCreators as offerActions} from "../../../store/Offer";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    paper: {
        overflow: 'hidden',
        margin: theme.spacing(3, 0)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '26px 26px',
    },
    sendBtn: {
        marginBottom: 20
    },
    deviationBtn: {
        "&:hover": {
            color: "#d80000"
        }
    }
});

class ChangeOfferStatus extends Component {

    render() {
        const formRef = createRef();
        const { classes, isOpen, offerId, closeChangeOfferStatusModal, offer } = this.props;

        let user = JSON.parse(localStorage.getItem('user'));
        return (
            <div>
                <Dialog
                    open={isOpen}
                    onClose={closeChangeOfferStatusModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={"xs"}
                    fullWidth={true}>
                    <DialogTitle id="alert-dialog-title">
                        Endre status
                    </DialogTitle>
                    <DialogContent>
                       
                        <Formik
                            innerRef={formRef}
                            initialValues={{
                                offerStatusId: '',
                                comment: '',
                            }}
                            validationSchema={Yup.object({
                                offerStatusId: Yup.string().required('Påkrevd.'),
                                comment: Yup.string(),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                let offerStatusForUpdate = {
                                    offerId: offerId,
                                    offerStatusId: values.offerStatusId,
                                    comment: values.comment,
                                    
                                };
                                this.props.offerActions.updateOfferStatus(offerStatusForUpdate, closeChangeOfferStatusModal);
                                
                                
                            }}>
                            <Form className={classes.form}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                                        <InputLabel id="offerStatusId-label">Velg ny status</InputLabel>
                                        <Field
                                            name="offerStatusId"
                                            variant="outlined"
                                            component={Select}
                                            labelWidth={105}>
                                            {(offer.offerStatusOptions || []).filter(x => x.visible && x.requiredUserRole <= (user.role === "SuperAdmin" ? 2 : user.role === "Admin" ? 1 : 0)).map((x, i)=>(
                                                <MenuItem key={i} value={x.offerStatusId}>{x.status}</MenuItem>
                                            ))}
                                        </Field>
                                    </FormControl>
                                    <Field
                                        margin="normal"
                                        component={TextField}
                                        name="comment"
                                        label="Kommentar"
                                        variant="outlined"
                                        multiline
                                        rows={5}
                                    />
                                </FormControl>
                                
                                <DialogActions>
                                    <Button color="primary" onClick={closeChangeOfferStatusModal}>
                                        Avbryt
                                    </Button>
                                    <Button color="primary" type={"submit"}>
                                        Oppdater status
                                    </Button>
                                </DialogActions>
                            </Form>
                        </Formik>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
    
    componentDidMount() {
        this.props.offerActions.getOfferStatusOptions();
    }
}

ChangeOfferStatus.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    offerId: PropTypes.number.isRequired,
};


function mapDispatchToProps(dispatch) {
    return {
        offerActions: bindActionCreators(offerActions, dispatch),
    };
}

function mapStateToProps(state) {
    return {
        offer: state.offer
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(withStyles(styles)(ChangeOfferStatus));