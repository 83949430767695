import React from "react";
import {createStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Paper from "@material-ui/core/Paper";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => createStyles({
    paper: {
        overflow: 'hidden',
        margin: theme.spacing(3, 0)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    contentWrapper: {
        margin: '40px 26px',
    },
    itemRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#efefef",
            textDecoration: "underline"
        }
    }
}));

const OfferEvaluationList = ({evaluationItems, createNewEvaluation}) => {
    const classes = useStyles();

    const openEvaluation = ({offerId, offerEvaluationId}) => {
        window.open(`/Offer/Evaluation/${offerId}/${offerEvaluationId}`, "_blank");
    };

    
    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <h3>Evalueringer</h3>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" className={classes.newEvaluation} onClick={() => createNewEvaluation()}>
                                Legg til ny evaluering
                            </Button>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <div className={classes.contentWrapper}>
                {evaluationItems.length !== 0 &&
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell algin="left">Id</TableCell>
                            <TableCell align="left">Registrert dato</TableCell>
                            <TableCell align="left">Registrert av</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {evaluationItems.map(row => (
                            <TableRow className={ classes.itemRow } key={row.offerEvaluationId} onClick={() => openEvaluation(row)}>
                                <TableCell align="left">{row.offerEvaluationId}</TableCell>
                                <TableCell align="left"><Moment>{row.createdAt}</Moment></TableCell>
                                <TableCell align="left">{row.createdBy.fullname}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                }

                {evaluationItems.length === 0 &&
                <Typography color="textSecondary" align="center">
                    Ingen evalueringer tilgjengelig
                </Typography>
                }
            </div>
        </Paper>
    );
};

export default (OfferEvaluationList);