import {getRequest, postRequest} from "../helpers/NetworkHelper";
import history from "../helpers/historyHelper";
import {actionCreators as alertActions } from "./Alert";

const requestStart = 'REQUEST_START';
const receiveError = 'RECEIVE_ERROR';
const receiveSuccess = 'RECEIVE_SUCCESS';

const receiveAuthenticationSuccess = 'RECEIVE_AUTH_SUCCESS';
const receiveOrganizationLookup = 'RECEIVE_ORG_LOOKUP';
const receiveVerifyForgotPasswordTokenSuccess = 'RECEIVE_VERIFY_PASSWORD_TOKEN_SUCCESS';
const receiveCheckRegistrationTokenSuccess = 'RECEIVE_CHECK_REGISTRATION_TOKEN_SUCCESS';
const clearAuthenticationStore = 'CLEAR_AUTH_STORE';

const initialState = { user: JSON.parse(localStorage.getItem('user')), org: {}, registrationEmail: '', forgotPasswordEmail: '', error: null, isLoading: false };

export const actionCreators = {
    login: (email, password) => async (dispatch, getState) => {
        dispatch({ type: requestStart });
        postRequest(`auth/login`, { Email: email, Password: password }, false, true, user => {
            if (user.token) {
                localStorage.setItem('user', JSON.stringify(user));
                dispatch({ type: receiveAuthenticationSuccess, user });
                if(user.organizationId === "") {
                    if(user.role === "Normal" || user.role === "Admin") {
                        history.push('/MyCases');
                    } else {
                        history.push('/IncomingCases');
                    }
                } else {
                    history.push('/home');
                }
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    register: (userForSaving, organizationForSaving) => async(dispatch, getState) => {
        dispatch({ type: requestStart });
        postRequest(`auth/register`, { UserForSaving: userForSaving, OrganizationForSaving: organizationForSaving }, false, true, user => {
            dispatch({ type: receiveSuccess });
            dispatch(alertActions.showSuccess("Din konto har blitt opprettet. Vi har sendt deg en link på epost for og bekrefte din konto."));
            history.push('/login');
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    checkRegistrationToken: (registrationToken) => async(dispatch, getState) => {
        dispatch({ type: requestStart });
        getRequest(`auth/CheckRegistrationToken/${registrationToken}`, null, false, email => {
            dispatch({ type: receiveCheckRegistrationTokenSuccess, email });
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
            history.push('/Login');
        });
    },
    completeUserRegistration: (userForSaving) => async(dispatch, getState) => {
        postRequest(`auth/CompleteUserRegistration`, userForSaving, false, true, user => {
            dispatch({ type: receiveSuccess });
            dispatch(alertActions.showSuccess("Din konto har blitt aktivert, du kan nå logge inn."));
            history.push('/login');
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    verifyRegistrationToken: (registrationToken) => async(dispatch, getState) => {
        dispatch({ type: requestStart });
        getRequest(`auth/VerifyRegistrationToken/${registrationToken}`, null, false, user => {
            dispatch({ type: receiveSuccess });
            dispatch(alertActions.showSuccess("Din konto er nå aktiv. Du kan nå logge inn."));
            history.push('/login');
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    forgotPassword: (email) => async(dispatch, getState) => {
        dispatch({type: requestStart});
        postRequest(`auth/ForgotPassword`, { Email: email }, false, true, user => {
            dispatch({ type: receiveSuccess });
            dispatch(alertActions.showSuccess("Vi har sendt deg en epost med instruksjoner for å tilbakestille passordet ditt."));
            history.push('/login');
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    verifyForgotPasswordToken: (resetPasswordToken) => async(dispatch, getState) => {
        dispatch({ type: requestStart });
        postRequest(`auth/VerifyForgotPasswordToken`, { ResetPasswordToken: resetPasswordToken }, false, true, email => {
            dispatch({ type: receiveVerifyForgotPasswordTokenSuccess, email });
        }).catch(error => {
            history.push('/login');
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    completeForgotPassword: (resetPasswordToken, newPassword) => async(dispatch, getState) => {
        dispatch({ type: requestStart });
        postRequest(`auth/CompleteForgotPassword`, { ResetPasswordToken: resetPasswordToken, NewPassword: newPassword }, false, true, user => {
            dispatch({ type: receiveSuccess });
            dispatch(alertActions.showSuccess("Din konto ble oppdatert med nytt passord. Du kan nå logge inn."));
            history.push('/login');
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
    logout: () => async (dispatch, getState) => {
        localStorage.removeItem('user');
        dispatch({type: clearAuthenticationStore});
        history.push('/login');
    },
    lookupOrganization: (orgnr) => async (dispatch, getState) => {
        dispatch({ type: requestStart });
        getRequest(`Auth/LookupOrganizationByOrgNr/${orgnr}`, null, true, data => {
            if (data) {
                dispatch({ type: receiveOrganizationLookup, data });
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, error});
        });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };
            
        case receiveAuthenticationSuccess:
            return {
                ...state,
                user: action.user,
                isLoading: false
            };
            
        case receiveCheckRegistrationTokenSuccess:
            return {
                ...state,
                registrationEmail: action.email,
                isLoading: false
            };
            
        case receiveVerifyForgotPasswordTokenSuccess:
            return {
                ...state,
                forgotPasswordEmail: action.email,
                isLoading: false
            };
            
        case receiveOrganizationLookup:
            return {
                ...state,
                isLoading: false,
                org: action.data
            };
            
        case receiveSuccess:
            return {
                ...state,
                isLoading: false
            };
            
        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case clearAuthenticationStore:
            return {
            };

        

        default:
            return state;
    }
};