import React, {Component} from 'react';
import { connect } from 'react-redux';
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import {withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as intranetActions} from "../../../store/Intranet";

import MaterialTable, {MTableToolbar} from 'material-table'

const styles = theme => ({
    paper: {
        overflow: 'hidden',
    },
    block: {
        display: 'block',
    },
    addChecklist: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 26px',
    },
});

class Organizations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddingChecklist: false
        }
    }
    
    render() {
        const {classes} = this.props;
        return (
            <div>
                <h1>Sjekklister</h1>
                
                    <div className={classes.contentWrapper}>

                        <MaterialTable
                            components={{
                                Toolbar: props => (
                                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                                            <MTableToolbar {...props} />
                                    </AppBar>
                                    
                                ),
                                Container: props => (
                                    <Paper className={classes.paper}>
                                        {props.children}
                                    </Paper>
                                
                                
                                )
                            }}
                            columns={[
                                { title: "Id", field: "CheckListTemplateId", cellStyle: {
                                        width: 20,
                                        maxWidth: 20
                                    }
                                },
                                { title: "Tittel", field: "Title" },
                            ]}
                            data={[
                                { CheckListTemplateId: "1", Title: "ERKLÆRINGSMATRISE PRODUSENT GENERELLE KRAV" },
                                { CheckListTemplateId: "2", Title: "INTERN EVALUERING" },

                            ]}
                            options={{showTitle:false, actionsColumnIndex: 3}}
                            editable={{
                                isEditable: rowData => rowData.name === "a", // only name(a) rows would be editable
                                isDeletable: rowData => rowData.name === "b", // only name(a) rows would be deletable
                                onRowAdd: newData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            resolve();
                                        }, 1000);
                                    }),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            resolve();
                                        }, 1000);
                                    }),
                                onRowDelete: oldData =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            resolve();
                                        }, 1000);
                                    })
                            }}
                        />
                    </div>
            </div>
        )
    };

    componentDidMount() {
        //this.props.intranetActions.getOrganizations();
    }
}

function mapDispatchToProps(dispatch) {
    return {
        intranetActions: bindActionCreators(intranetActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        intranet: state.intranet,
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Organizations));
