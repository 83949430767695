import React, {Component} from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {actionCreators} from "../../store/Authentication";
import historyHelper from "../../helpers/historyHelper";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/login-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 2),
    },
});

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            submitted: false,
        };
    }
    
    componentDidMount() {
        console.log(this.props.match);
        const {registrationToken} = this.props.match.params;
        if(registrationToken !== undefined) {
            this.props.verifyRegistrationToken(registrationToken);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = this.state;
        this.setState({ submitted: true });

        if (email && password) {
            this.props.login(email, password);
        }

    };
    
    render()
    {
        const {classes} = this.props;
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Logg inn
                        </Typography>

                        <Formik
                            initialValues={{
                                email: '',
                                password: ''
                            }}
                            validationSchema={Yup.object({
                                email: Yup.string()
                                    .email('Må være en gyldig epost.')
                                    .required('Påkrevd.'),
                                password: Yup.string()
                                    .required('Påkrevd.'),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                this.props.login(values.email, values.password);
                                //this.props.useraccountStepComplete(values);
                            }}>
                            <Form className={classes.form}>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        margin="normal"
                                        component={TextField}
                                        name="email"
                                        label="E-post"
                                        autoFocus
                                        variant="outlined"
                                    />
                                </FormControl>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        margin="normal"
                                        component={TextField}
                                        name="password"
                                        label="Passord"
                                        type="password"
                                        variant="outlined"
                                        autoComplete="current-password"
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Logg inn
                                </Button>
                            </Form>
                        </Formik>
                            
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" onClick={() => historyHelper.push('/Forgot')} variant="body2">
                                    Glemt passord?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" onClick={() => historyHelper.push('/Registration')} variant="body2">
                                    {"Ny leverandør"}
                                </Link>
                            </Grid>
                        </Grid>
                        <Box mt={5}>

                        </Box>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    state => state.authentication,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(Login));