import {begin, pendingTask, end} from "react-redux-spinner";
import {getRequest, postRequest} from "../helpers/NetworkHelper";
import {actionCreators as alertActions } from "./Alert";

const requestStart = 'REQUEST_START';
const receiveError = 'RECEIVE_ERROR';
const receiveSuccess = 'RECEIVE_SUCCESS';

const receiveDeclarations = 'RECEIVE_DECLARATIONS';
const receiveDeclarationChecklistItemsTemplate = 'RECEIVE_DECLARATIONS_CHECKLIST_ITEMS_TEMPLATE';

const initialState = { declarations: [], declarationsCheckListItemsTemplate: [], error: null, isLoading: false };

export const actionCreators = {
    getDeclarations: (organizationId) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Organization/GetDeclarations/${organizationId}`, null, true, data => {
            if (data) {
                dispatch({type:receiveDeclarations, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    getDeclarationChecklistItemsTemplate: () => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        getRequest(`Organization/GetDeclarationChecklistItemsTemplate`, null, true, data => {
            if (data) {
                dispatch({type:receiveDeclarationChecklistItemsTemplate, [ pendingTask ]: end, data});
            }
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    },
    addNewDeclaration: (declarationForSaving, callback = null) => async (dispatch, getState) => {
        dispatch({type: requestStart, [ pendingTask ]: begin});
        postRequest(`Organization/AddNewDeclaration`, declarationForSaving, true, true,()=>{
            dispatch({type:receiveSuccess, [ pendingTask ]: end});
            callback && callback(null,true);
        }).catch(error => {
            dispatch(alertActions.showError(error));
            dispatch({type:receiveError, [ pendingTask ]: end, error});
        });
    }
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch(action.type) {
        case requestStart:
            return {
                ...state,
                isLoading: true
            };

        case receiveDeclarations:
            return {
                ...state,
                isLoading: false,
                declarations: action.data
            };
        case receiveDeclarationChecklistItemsTemplate:
            return {
                ...state,
                isLoading: false,
                declarationsCheckListItemsTemplate: action.data
            };
            
        case receiveSuccess:
            return {
                ...state,
                isLoading: false,
            };
        case receiveError:
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        default:
            return state;
    }
};