import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import * as Authentication from "./Authentication";
import * as Intranet from "./Intranet";
import * as Equipment from "./Equipment";
import * as Offer from "./Offer";
import * as Deviation from "./Deviation";
import * as User from "./User";
import {pendingTasksReducer} from "react-redux-spinner";
import * as Organization from "./Organization";

export default function configureStore () {
  const reducers = {
    authentication: Authentication.reducer,
    intranet: Intranet.reducer,
    equipment: Equipment.reducer,
    offer: Offer.reducer,
    deviation: Deviation.reducer,
    user: User.reducer,
    organization: Organization.reducer,
  };

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
    enhancers.push(window.devToolsExtension());
  }

  const rootReducer = combineReducers({
    ...reducers,
    pendingTasks: pendingTasksReducer,
  });

  return createStore(
    rootReducer,
    compose(applyMiddleware(thunk), ...enhancers)
  );
}
