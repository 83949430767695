import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import {bindActionCreators} from "redux";
import {actionCreators as intranetActions} from "../../../store/Intranet";
import { makeStyles, createStyles } from '@material-ui/core/styles';
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddUser from "../Modal/AddUser";
import EditUser from "./Modal/EditUser";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
}));

function mapDispatchToProps(dispatch) {
    return {
        intranetActions: bindActionCreators(intranetActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        intranet: state.intranet,
    }
}

const Users = ({intranet,intranetActions}) => {
    const classes = useStyles();
    const [addUserIsOpen, setAddUserIsOpen] = useState(false);
    const [editUserIsOpen, setEditUserIsOpen] = useState(false);
    const [editUserData, setEditUserData] = useState([]);
    useEffect(() => {
        if(editUserIsOpen === false) {
            setEditUserData([]);
            intranetActions.getUsers();
        }
    }, [editUserIsOpen]);
    
    useEffect(() => {
        intranetActions.getUsers();
    }, []);

    const deleteUser = (organizationId, userId) => {
        if(!window.confirm("Er du sikker?")) return;
        intranetActions.deleteUser(userId, reloadUsers);
    };

    const reloadUsers = () => {
        intranetActions.getUsers();
    };

    let userObj = JSON.parse(localStorage.getItem('user'));
    return (
        <div>
            <AddUser isOpen={addUserIsOpen} setIsOpen={(value) => setAddUserIsOpen(value)} classes={classes} addUser={intranetActions.addNewUser}/>
            <EditUser isOpen={editUserIsOpen} setIsOpen={(value) => setEditUserIsOpen(value)} userData={editUserData} classes={classes} updateUser={intranetActions.updateUser}/>
            <h1>Brukere</h1>
            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" className={classes.newUser}
                                        onClick={() => setAddUserIsOpen(true)}>
                                    Legg til bruker
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    {intranet.users.length !== 0 &&
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell algin="left">Id</TableCell>
                                <TableCell align="left">Navn</TableCell>
                                <TableCell align="left">Epost</TableCell>
                                <TableCell align="left">Tlf</TableCell>
                                <TableCell align="left">Rolle</TableCell>
                                <TableCell align="left">Registrert</TableCell>
                                <TableCell align="right"/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {intranet.users.map(row => (
                                <TableRow className={classes.itemRow} key={row.userId}>
                                    <TableCell align="left">{row.userId}</TableCell>
                                    <TableCell align="left">{row.fullname}</TableCell>
                                    <TableCell align="left">{row.email}</TableCell>
                                    <TableCell align="left">{row.cellPhone}</TableCell>
                                    <TableCell align="left">{row.userRole === 0 ? "Normal" : row.userRole === 1 ? "Admin" : "Superadmin"}</TableCell>
                                    <TableCell align="left"><Moment>{row.RegisteredDateTime}</Moment></TableCell>
                                    <TableCell aling="right">
                                        <IconButton aria-label="edit" onClick={() => {
                                            setEditUserData(row);
                                            setEditUserIsOpen(true);
                                        }}>
                                            <EditIcon/>
                                        </IconButton>
                                        {row.userId !== userObj.userId &&
                                        <IconButton aria-label="delete" onClick={() => deleteUser(row.organizationId, row.userId)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }

                    {intranet.users.length === 0 &&
                    <Typography color="textSecondary" align="center">
                        Ingen tilbud tilgjengelig
                    </Typography>
                    }
                </div>
            </Paper>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);