import React, {Component} from "react";
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import {actionCreators as intranetActions} from "../../../../store/Intranet";

import {bindActionCreators} from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const styles = theme => ({
});

const initialState = {
    organizationForSaving: {
        OrganizationNr: "",
        Name: "",
        StreetAddress: "",
        City: "",
        ZipCode: "",
        Telephone: "",
    }
};

class AddEditOrganization extends Component {
    
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.state = initialState;
    }
    
    handleChange = name => event => {
        if(name==="OrganizationNr"){
            let orgNr = event.target.value;
            orgNr = orgNr.replace(' ', '').replace('-','');
            if(orgNr.length >= 9) {

                let controlNumber = 2,
                    sumForMod = 0,
                    i;

                for (i = orgNr.length - 2; i >= 0; --i) {
                    sumForMod += orgNr.charAt(i) * controlNumber;
                    if (++controlNumber > 7)
                        controlNumber = 2;
                }
                let result = (11 - sumForMod % 11);

                if((result === 11 ? 0 : result) === parseInt(orgNr.charAt(orgNr.length - 1), 10) && orgNr !== this.props.lastValidOrgNr) {
                    this.props.intranetActions.lookupOrganization(orgNr);
                }
            }
        }
        var n = name;
        var val = event.target.value;
        this.setState(state => ({
            organizationForSaving: {
                ...state.organizationForSaving,
                [n]: val
            }
        }));
    };

    handleClose() {
        this.setState(initialState);
        this.props.cancelAddOrganization && this.props.cancelAddOrganization();
    };


    fillOrganizationData(org) {
        if(org && org.posts && org.posts > 0) {
            this.setState(state => ({
                organizationForSaving: {
                    ...state.organizationForSaving,
                    Name: org.entries[0].navn,
                    Telephone: org.entries[0].tlf !== "" ? org.entries[0].tlf : org.entries[0].tlf_mobil,
                    StreetAddress: org.entries[0].forretningsadr,
                    City: org.entries[0].forradrpoststed,
                    ZipCode: org.entries[0].forradrpostnr,
                }
            }))
        }
    }

    handleSubmit() {
        this.props.intranetActions.createOrganization(this.state.organizationForSaving);
    };

    

    render() {
        const {classes, isAddingOrganization} = this.props;
        return (

            <Dialog
                open={isAddingOrganization}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Legg til organisasjon"}</DialogTitle>
                <DialogContent>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <TextField
                            name="OrganizationNr"
                            label="Org.Nr"
                            value={this.state.organizationForSaving.OrganizationNr}
                            onChange={this.handleChange('OrganizationNr')}
                            style={{width: '100%', margin: '10px 0'}}
                            variant="outlined"
                            required
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <TextField
                            name="Name"
                            label="Org.Navn"
                            value={this.state.organizationForSaving.Name}
                            onChange={this.handleChange('Name')}
                            style={{width: '100%', margin: '10px 0'}}
                            variant="outlined"

                            required
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <TextField
                            name="Telephone"
                            label="Telefon"
                            value={this.state.organizationForSaving.Telephone}
                            onChange={this.handleChange('Telephone')}
                            style={{width: '100%', margin: '10px 0'}}
                            variant="outlined"

                            required
                        />
                    </FormControl>

                    <FormControl className={classes.formControl} fullWidth={true}>
                        <TextField
                            name="StreetAddress"
                            label="Adresse"
                            value={this.state.organizationForSaving.StreetAddress}
                            onChange={this.handleChange('StreetAddress')}
                            style={{width: '100%', margin: '10px 0'}}
                            variant="outlined"

                            required
                        />
                    </FormControl>
                    <TextField
                        name="ZipCode"
                        label="Post.Nr"
                        value={this.state.organizationForSaving.ZipCode}
                        onChange={this.handleChange('ZipCode')}
                        style={{width: '28%', margin: '10px 0', float: "left"}}
                        variant="outlined"

                        required
                    />
                    <TextField
                        name="City"
                        label="By"
                        value={this.state.organizationForSaving.City}
                        onChange={this.handleChange('City')}
                        style={{width: '68%', margin: '10px 0', float: "right"}}
                        variant="outlined"

                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" autoFocus>
                        Legg til
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.intranet.org !== this.props.intranet.org) {
            this.fillOrganizationData(this.props.intranet.org);
        }
    }
}

function mapDispatchToProps(dispatch) {
    return {
        intranetActions: bindActionCreators(intranetActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        intranet: state.intranet,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddEditOrganization));
