import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Moment from "react-moment";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {createStyles, withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as organizationActions} from "../../../store/Organization";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddCompanyDeclaration from "./Modal/AddCompanyDeclaration";
import ViewCompanyDeclaration from "./Modal/ViewCompanyDeclaration";

const useStyles = makeStyles((theme) => createStyles({
    paper: {
        overflow: 'hidden',
        margin: theme.spacing(3, 0)
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '26px 26px',
    },
    sendBtn: {
        marginBottom: 20
    },
    deviationBtn: {
        "&:hover": {
            color: "#d80000"
        }
    },
    itemRow: {
        cursor: "pointer",
        "&:hover": {
            background: "#efefef",
            textDecoration: "underline"
        }
    }
}));

const OrganizationDeclarations = ({organization,organizationActions, organizationId}) => {
    
    const classes = useStyles();
    
    const [checkListItemsView, setCheckListItemsView] = useState([]);
    const [isViewingDeclaration, setIsViewingDeclaration] = useState(false);
    
    const [isAddingDeclaration, setIsAddingDeclaration] = useState(false);
    
    useEffect(() => {
        organizationActions.getDeclarations(organizationId);
    }, []);
    
    useEffect(() => {
        if(!isAddingDeclaration){
            organizationActions.getDeclarations(organizationId);
        }
    }, [isAddingDeclaration, organizationId]);
    
    
    const openDeclaration = (checkListItems) => {
        setCheckListItemsView(checkListItems);
        setIsViewingDeclaration(true);
    };
    return (
        <div>
            <ViewCompanyDeclaration isOpen={isViewingDeclaration} setIsOpen={setIsViewingDeclaration} checkListItems={checkListItemsView} />
            <AddCompanyDeclaration isOpen={isAddingDeclaration} setIsOpen={setIsAddingDeclaration} checkListItems={organization.declarationsCheckListItemsTemplate} addCompanyDeclaration={organizationActions.addNewDeclaration} />
            <Paper className={classes.paper}>
                <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                    <Toolbar>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs>
                                <h3>Oversikt over registrerte firmaerklæringer</h3>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="primary" className={classes.newOrganizationDeclaration}
                                        onClick={() => {
                                            organizationActions.getDeclarationChecklistItemsTemplate();
                                            setIsAddingDeclaration(true);
                                        }}>
                                    Legg til Leverandørerklæring
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <div className={classes.contentWrapper}>
                    {organization.declarations.length !== 0 &&
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell algin="left">Id</TableCell>
                                <TableCell align="left">Registrert dato</TableCell>
                                <TableCell align="left">Utløpsdato</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {organization.declarations.map(row => (
                                <TableRow className={ classes.itemRow } key={row.organizationDeclarationId} onClick={() => openDeclaration(row.checkListData?.checkListItemData)}>
                                    <TableCell align="left">{row.organizationDeclarationId}</TableCell>
                                    <TableCell align="left"><Moment>{row.createdAt}</Moment></TableCell>
                                    <TableCell align="left"><Moment>{row.expiresAt}</Moment></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    }

                    {organization.declarations.length === 0 &&
                    <Typography color="textSecondary" align="center">
                        Ingen erklæringer tilgjengelig
                    </Typography>
                    }
                </div>
            </Paper>
        </div>
        
    )
}

function mapDispatchToProps(dispatch) {
    return {
        organizationActions: bindActionCreators(organizationActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        organization: state.organization,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDeclarations);