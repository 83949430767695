import React, {Component, useEffect} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import {makeStyles} from "@material-ui/core";
import {actionCreators as authenticationActions} from "../../store/Authentication";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/login-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: "10px 0",
    },
    errorMsg: {
        color: 'red'
    },
    fullWidth: {
        width: '100%'
    }
}));

function mapDispatchToProps(dispatch) {
    return {
        authenticationActions: bindActionCreators(authenticationActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication,
    }
}

const UserRegistration = ({match, authentication, authenticationActions}) => {
    const classes = useStyles();

    useEffect(() => {
        const {registrationToken} = match.params;
        authenticationActions.checkRegistrationToken(registrationToken)
    }, []);
    
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline/>
            <Grid item xs={false} sm={4} md={7} className={classes.image}/>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Fullfør registrering av bruker
                    </Typography>
                    <div className={classes.fullWidth}>
                        <Formik
                            enableReinitialize={true}
                            initialValues={{email: authentication.registrationEmail }}
                            validationSchema={Yup.object({
                                firstname: Yup.string()
                                    .required('Påkrevd.'),
                                lastname: Yup.string()
                                    .max(256, 'Må være mindre eller lik 256 karakterer.')
                                    .required('Påkrevd.'),
                                email: Yup.string()
                                    .max(256, 'Må være mindre eller lik 256 karakterer.')
                                    .email('Må være en gyldig epost.')
                                    .required('Påkrevd.'),
                                cellPhone: Yup.string()
                                    .max(256, 'Må være mindre eller lik 256 karakterer.')
                                    .required('Påkrevd.'),
                                password1: Yup.string()
                                    .max(56, 'Må være mindre eller lik 56 karakterer.')
                                    .required('Påkrevd.')
                                    .matches(
                                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                                        "Må inneholde 8 karakterer, stor bokstav, små bokstav og tall"
                                    ),
                                password2: Yup.string()
                                    .oneOf([Yup.ref('password1'), null], 'Passordbekreftelsen samsvarer ikke med passordet.')
                                    .max(56, 'Må være mindre eller lik 56 karakterer.')
                                    .required('Påkrevd.'),
                            })}
                            onSubmit={(values, {setSubmitting}) => {
                                setSubmitting(false);
                                const {registrationToken} = match.params;
                                authenticationActions.completeUserRegistration({
                                    Email: values.email,
                                    FirstName: values.firstname,
                                    LastName: values.lastname,
                                    CellPhone: values.cellPhone,
                                    Password1: values.password1,
                                    Password2: values.password2,
                                    RegistrationToken: registrationToken
                                });
                            }}>
                            <Form>
                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        component={TextField}
                                        name="firstname"
                                        label="Fornavn"
                                        variant="outlined"
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        name="lastname"
                                        label="Etternavn"
                                        variant="outlined"
                                        component={TextField}
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        name="cellPhone"
                                        label="Mobilnummer"
                                        variant="outlined"
                                        component={TextField}
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        name="email"
                                        label="Epost"
                                        component={TextField}
                                        variant="outlined"
                                        disabled={true}
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        type="password"
                                        name="password1"
                                        label="Passord"
                                        component={TextField}
                                        variant="outlined"
                                    />
                                </FormControl>

                                <FormControl className={classes.formControl} fullWidth={true}>
                                    <Field
                                        type="password"
                                        name="password2"
                                        label="Passord bekreftelse"
                                        component={TextField}
                                        variant="outlined"
                                    />
                                </FormControl>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.completeButton}
                                >
                                    Registrer
                                </Button>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};



export default connect(mapStateToProps, mapDispatchToProps)(UserRegistration)