import React, {useEffect, useRef, useState} from "react";
import {bindActionCreators} from "redux";
import {actionCreators as offerActions} from "../../store/Offer";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => createStyles({
    checkListItem: {
        lineHeight: "40px",
        padding: "12px 12px",
        borderBottom: "1px solid lightgray",
        fontWeight: "normal",
        fontSize: "15px",
        fontFamily: "helvetica",
        "&:last-child": {
        },
        "&:hover": {
            background: "rgba(240,240,240,0.4)"
        }
    },
    toggleButton: {
        "&.Mui-selected&:nth-child(1)": {
            background: "lightgreen",
            color: "white"
        },
        "&.Mui-selected&:nth-child(2)": {
            background: "lightcoral",
            color: "white"
        },
        "&.Mui-selected&:nth-child(3)": {
            borderLeft: "1px solid lightgray",
            background: "lightyellow",
            color: "black"
        }

    },
    closeEvaluation: {
        marginTop: 15
    },
    checkListContainer: {
        width: "100%",
    },
    accordionDetails: {
        display: 'block'
    },
    paper: {
        overflow: 'hidden',
        marginBottom: 30,
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    contentWrapper: {
        margin: '40px 26px',
    }
}));

const CheckListItem = ({item, updateCheckListItemData, classes}) => {

    const [checklist, setChecklist] = useState({wait: false, data: item});

    useEffect(() => {
        updateCheckListItem()
    }, [checklist]);
    
    
    const handleInputChangeCheckbox = () => (event, newValue) => {
        setChecklist((prevState) => ({
            ...prevState,
            wait: false,
            data: {
                ...prevState.data,
                intValue:  parseInt(newValue)
            }
        }))
    };

    const handleInputChangeComment = (auditor, wait) => event => {
        const target = event.target;
        if(target !== null) {
            setChecklist((prevState) => ({
                ...prevState,
                wait: wait,
                data: {
                    ...prevState.data,
                    [auditor ? "commentAuditor" : "comment"]: target.value
                }
            }))
        }
    };

    const updateCheckListItem = () => {
        if(checklist.wait) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                updateCheckListItemData(checklist.data);
            }, 1000);
        } else {
            clearTimeout(timeout);
            updateCheckListItemData(checklist.data);
        }
    };
    
    if(checklist.data.type === 5) {
        return (
            <h3>
                {checklist.data.title}
            </h3>
        )
    }
    
    return (
        <Grid container key={checklist.data.checkListItemDataId} className={classes.checkListItem} spacing={2}>
            <Grid item xs={6}>
                <Grid item xs={12}>
                    {checklist.data.type !== 5  ? checklist.data.title : (<h3>{checklist.data.title}</h3>)}
                </Grid>

                {checklist.type !== 5 &&
                <Grid item xs={12} style={{textAlign: 'left'}}>
                    <ToggleButtonGroup size="small" value={1} exclusive onChange={handleInputChangeCheckbox()}>
                        <ToggleButton className={classes.toggleButton} key={1} value="1" selected={(checklist.data.intValue || null) === 1}>
                            Ja
                        </ToggleButton>
                        <ToggleButton className={classes.toggleButton} key={2} value="2" selected={(checklist.data.intValue || null) === 2}>
                            Nei
                        </ToggleButton>
                        <ToggleButton className={classes.toggleButton} key={3} value="3" selected={(checklist.data.intValue || null) === 3}>
                            Ikke relevant
                        </ToggleButton>
                    </ToggleButtonGroup>
                    &nbsp;&nbsp;
                </Grid>
                }
            </Grid>
            {checklist.type !== 5 ? (
                <>
                <Grid item xs={3} style={{textAlign: 'right'}}>
                    <TextField
                        name="comments"
                        label='Kommentar revisor'
                        value={checklist.data.commentAuditor || ''}
                        variant="outlined"
                        fullWidth={true}
                        rows={3}
                        onChange={handleInputChangeComment(true,true)}
                        onBlur={handleInputChangeComment(true,false)}
                        InputProps={{ classes: { input: classes.productComment } }}
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </Grid>
                <Grid item xs={3} style={{textAlign: 'right'}}>
                    <TextField
                        name="comments"
                        label='Kommentar saksbehandler'
                        value={checklist.data.comment || ''}
                        variant="outlined"
                        fullWidth={true}
                        rows={3}
                        onChange={handleInputChangeComment(false,true)}
                        onBlur={handleInputChangeComment(false, false)}
                        InputProps={{ classes: { input: classes.productComment } }}
                        multiline
                        InputLabelProps={{
                            shrink: true,
                        }}
                        required
                    />
                </Grid>
                </>
            ) : (<Grid item xs={4} />)
            }

        </Grid>
    )
}

let timeout = null;
const OfferEvaluation = ({ match, offerActions, stateOffer}) => {
    document.body.style = 'background: #eaeff1;';
    useEffect(() => {
        const {evaluationId} = match.params;
        offerActions.getEvaluation(evaluationId);
    }, []);

    useEffect(() => {
        if(stateOffer.evaluation) {
            console.log(stateOffer);
            setCheckListData(stateOffer.evaluation.checkListData)
        }
    }, [stateOffer.evaluation]);

    const [checkListData, setCheckListData] = useState([]);
    
    const classes = useStyles();
    
    return (
        <div style={{width: "100%", padding: 10}}>
            <Grid container>
                <Grid item xs>
                    <Typography variant="h4" component="h2">
                        Evaluering av produkt
                    </Typography>
                    <Typography variant="subtitle1" component="h2">
                        {stateOffer.evaluation?.offer?.title}
                    </Typography>
                </Grid>
            </Grid>
            <div className={classes.checkListContainer}>

                <Divider />
                <br/>
                {checkListData &&
                (checkListData[0]?.checkListItemData || []).map((item)=> (
                    <div key={item.checkListItemDataId}>
                        {item.type === 5 ? (
                                <Paper className={classes.paper}>
                                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                                        <Toolbar>
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs>
                                                    <CheckListItem item={item} classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                                </Grid>
                                                <Grid item>
                                                </Grid>
                                            </Grid>
                                        </Toolbar>
                                    </AppBar>
                                    <div className={classes.contentWrapper}>
                                        {item.checkListItems && (item.checkListItems || []).map((item2)=> (
                                            <div key={item2.checkListItemDataId}>
                                                <CheckListItem item={item2} classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                                {item2.checkListItems && (item2.checkListItems || []).map((item3)=> (
                                                    <div key={item3.checkListItemDataId}>
                                                        <CheckListItem item={item3}  classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </Paper>
                        ) : (
                            <div key={item.checkListItemDataId}>
                                <CheckListItem item={item}  classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                {item.checkListItems && (item.checkListItems || []).map((item2)=> (
                                    <div key={item2.checkListItemDataId}>
                                        <CheckListItem item={item2} classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                        {item2.checkListItems && (item2.checkListItems || []).map((item3)=> (
                                            <div key={item3.checkListItemDataId}>
                                                <CheckListItem item={item3} classes={classes} updateCheckListItemData={offerActions.updateCheckListItemData} />
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))
                }
            </div>
            <Button variant="contained" color="primary" className={classes.closeEvaluation} onClick={() => window.close()}>
                Lukk vindu
            </Button>
        </div>
    )
};

function mapDispatchToProps(dispatch) {
    return {
        offerActions: bindActionCreators(offerActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        stateOffer: state.offer
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferEvaluation)