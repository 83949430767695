import React, {Component, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {connect} from "react-redux";
import {createStyles, withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as equipmentActions} from "../../../../store/Equipment";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => createStyles({
    productComment: {
        height: '91px!important'
    }
}));

let timeout = null;

const Product = ({equipments, setEquipments, equipmentActions, equipment, disableFields}) => {

    const [localEquipments, setLocalEquipments] = useState([]);

    useEffect(() => {
        if(equipments && JSON.stringify(equipments) !== JSON.stringify(localEquipments)){
            setLocalEquipments(equipments);
        }
    }, [equipments]);

    useEffect(() => {
        equipmentActions.getCategories();
    }, []);

    useEffect(() => {
        updateProduct(true);
    }, [localEquipments]);

    const handleInputChangeProduct = (wait) => event => {
        const target = event.target;
        if(target !== null) {
            let name = event.target.name;
            const value = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseFloat(target.value) : target.value;
            setLocalEquipments([{ ...localEquipments[0], [name]: value }]);
        } else {
            updateProduct(false);
        }
    };

    const updateProduct = (wait) => {
        let equipmentForSaving = localEquipments[0];
        let updateEquipment = equipmentActions.updateEquipment;
        if(wait) {
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                updateEquipment(equipmentForSaving);
                setEquipments(localEquipments);
            }, 1000);
        } else {
            clearTimeout(timeout);
            updateEquipment(equipmentForSaving);
            setEquipments(localEquipments);
        }
    };
    
    const classes = useStyles();
    const {categories} = equipment;
    
    /*if(equipments[0]?.equipmentNr === '') {
        setErrors([...errors, "Produktnummer mangler"]);
    }*/
        
    return (
        <div>
            {localEquipments &&
            localEquipments.map((item)=> {
                return (
                <Grid container spacing={2} key={item.equipmentId}>
                    <Grid item xs={2}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="equipmentNr"
                                label="Produktnr"
                                value={item.equipmentNr || ''}
                                error={(item.equipmentNr || '') === ''}
                                variant="outlined"
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="name"
                                label="Produkt navn"
                                value={item.name || ''}
                                variant="outlined"
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel id="select-equipmentcategory-label">
                                Utstyrsgruppe
                            </InputLabel>
                            <Select
                                id="select-equipmentcategory-outlined"
                                name='equipmentCategoryId'
                                value={item.equipmentCategoryId || ''}
                                onChange={handleInputChangeProduct(false)}
                                labelWidth={107}
                                disabled={disableFields}>
                                <MenuItem value="">
                                    <em>Velg en kategori</em>
                                </MenuItem>
                                {categories && categories.map((item, i) => (
                                    <MenuItem key={i} value={item.equipmentCategoryId}>{item.category}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="maxHs"
                                label="Maks bølge (Hs)"
                                value={item.maxHs || ''}
                                error={(item.fixedMounting === true && (item.maxHs || '') === '')}
                                variant="outlined"
                                type='number'
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="maxVc"
                                label="Maks strøm (Vc)"
                                value={item.maxVc || ''}
                                error={(item.fixedMounting === true && (item.maxVc || '') === '')}
                                variant="outlined"
                                type='number'
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="maxIcing"
                                label="Maks ispåslag"
                                value={item.maxIcing || ''}
                                variant="outlined"
                                type='number'
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel shrink id="select-fixedmounting-label">
                                Fastmontert
                            </InputLabel>
                            <Select
                                id="select-fixedmounting-outlined"
                                name='fixedMounting'
                                value={item.fixedMounting != null ? item.fixedMounting.toString() : ''}
                                onChange={handleInputChangeProduct(false)}
                                labelWidth={90}
                                disabled={disableFields}>
                                <MenuItem selected value={''}>
                                    <em>Velg</em>
                                </MenuItem>
                                <MenuItem value={true}>Ja</MenuItem>
                                <MenuItem value={false}>Nei</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel shrink id="select-permanentUsage-label">
                                For permanent bruk
                            </InputLabel>
                            <Select
                                id="select-permanentUsage-outlined"
                                name='permanentUsage'
                                value={item.permanentUsage != null ? item.permanentUsage.toString() : ''}
                                onChange={handleInputChangeProduct(false)}
                                labelWidth={145}
                                disabled={disableFields}>
                                <MenuItem selected value={''}>
                                    <em>Velg</em>
                                </MenuItem>
                                <MenuItem value={true}>Ja</MenuItem>
                                <MenuItem value={false}>Nei</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl variant="outlined" className={classes.formControl} fullWidth={true}>
                            <InputLabel shrink id="select-certified-label">
                                Sertifisert
                            </InputLabel>
                            <Select
                                id="select-certified-outlined"
                                name='certified'
                                value={item.certified != null ? item.certified.toString() : ''}
                                onChange={handleInputChangeProduct(false)}
                                labelWidth={80}
                                disabled={disableFields}>
                                <MenuItem selected value={''}>
                                    <em>Velg</em>
                                </MenuItem>
                                <MenuItem value={true}>Ja</MenuItem>
                                <MenuItem value={false}>Nei</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={ classes.formControl } fullWidth={true}>
                            <TextField
                                name="comments"
                                label='Kommentar'
                                value={item.comments || ''}
                                variant="outlined"
                                onChange={handleInputChangeProduct(true)}
                                onBlur={handleInputChangeProduct(false)}
                                disabled={disableFields}
                                InputProps={{ classes: { input: classes.productComment } }}
                                multiline
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            )})}
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        equipmentActions: bindActionCreators(equipmentActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        equipment: state.equipment
    }
}

export default connect(
    mapStateToProps, mapDispatchToProps
)(Product);