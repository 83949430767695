import React, {useState} from 'react';
import { Document, Page } from "react-pdf";
import { pdfjs } from 'react-pdf';
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import clsx from "clsx";
import history from "../../helpers/historyHelper";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Divider from "@material-ui/core/Divider";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const styles = theme => ({
    pdfHeader: {
        height: 120,
        overflow: 'hidden'
    },
    listItem: {
        opacity: '1!important',
        paddingTop: 1,
        paddingBottom: 1,
    },
    listItemText:{
        '& span': {

            fontSize:'12px',//Insert your required size
        }
    }
});

let categories = [
        { id: 'Innledning', link: '#', pages: [2]},
        { id: 'Generelle krav', link: '#', pages: [3,4,5]},
        { id: 'Krav til:', link: '#', pages: []},
        { id: '-- Luseskjørt', link: '#', pages: [6]},
        { id: '-- Forspredere og Forslanger', link: '#', pages: [7]},
        { id: '-- El-skap og tilsvarende skap', link: '#', pages: [8]},
        { id: '-- Undervannskamera og veierammer', link: '#', pages: [9]},
        { id: '-- Rensefiskutstyr i merd', link: '#', pages: [10]},
        { id: '-- Fugletak og holdere', link: '#', pages: [11]},
        { id: '-- Systemer for opptak av dødfisk', link: '#', pages: [12]},
        { id: '-- Sensorer, diffusorer og undervannslys', link: '#', pages: [13]},
        { id: '-- Fôrautomater for rensefisk', link: '#', pages: [14]},
        { id: '-- Nedloddingssystem', link: '#', pages: [15]},
        { id: '-- Leveringsline, kuleline og avkastnot', link: '#', pages: [16]},
        { id: '-- Autonome vaskeroboter', link: '#', pages: [17]}
    ];


function PdfPreview(props) {
    const {pages, setPages, classes, isOpen, setIsOpen} = props;
    
    return (
        <Dialog aria-labelledby="simple-dialog-title" open={isOpen} scroll="body" maxWidth={false} onClose={() => setIsOpen(false)}>
            <div style={{width: 960}}>
                <DialogTitle id="simple-dialog-title">LSG Standard</DialogTitle>
                <DialogContent dividers>
                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={3}>
                            {categories.map(({ id, pages: cPages  }) => (
                                <ListItem
                                    disabled={cPages.length === 0}
                                    className={classes.listItem}
                                    key={id}
                                    onClick={() => {
                                        setPages(cPages)
                                    }}
                                    button>
                                    <ListItemText className={classes.listItemText}>
                                        {id}
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </Grid>
                        <Grid item xs={9}>
                            <Document file="/lsgstandard.pdf">
                                <Page pageNumber={1} width={700} height={100} className={classes.pdfHeader} />
                                {pages.map((page) => (
                                    <Page key={page} pageNumber={page} width={700} height={600} />
                                ))}
                            </Document>
                        </Grid>
                    </Grid>
                </DialogContent>
            </div>
        </Dialog>
    );
    
}

export default withStyles(styles)(PdfPreview)