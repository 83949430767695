import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actionCreators} from "../../store/Authentication";
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/core/SvgIcon/SvgIcon";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Step from "@material-ui/core/Step";
import Stepper from "@material-ui/core/Stepper";
import StepLabel from "@material-ui/core/StepLabel";
import historyHelper from "../../helpers/historyHelper";
import OrganizationStep from "./parts/registration/OrganizationStep";
import UseraccountStep from "./parts/registration/UseraccountStep";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = theme => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(/login-bg.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        margin: "10px 0",
    },
    errorMsg: {
        color:'red'
    },
    fullWidth: {
        width: '100%'
    }
});

const steps = ['Firmainformasjon', 'Brukerkonto'];

class Registration extends Component {

    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            activeStep: 0,
            acceptedEthicalGuidance: false,
            userAccountForSaving: {
                email: '',
                firstname: '',
                lastname: '',
                cellPhone: '',
                password1: '',
                password2: '',
            },
            organizationForSaving: {
                organizationNr: '',
                name: '',
                telephone: '',
                streetAddress: '',
                zipCode: '',
                city: '',
            }
        };
    }
    
    getStepContent = (step) => {
        switch (step) {
            case 0:
                return <OrganizationStep 
                    initialValue={this.state.organizationForSaving}
                    classes={this.props.classes} 
                    wizardActions={this.wizardActions()} 
                    lookUpOrganization={this.props.lookupOrganization}
                    organizationStepComplete={this.organizationStepComplete}
                    />;
            case 1:
                return <UseraccountStep
                    initialValue={this.state.userAccountForSaving}
                    classes={this.props.classes}
                    wizardActions={this.wizardActions()}
                    useraccountStepComplete={this.useraccountStepComplete}
                />;
            default:
                return 'Unknown step';
        }
    };
    
    organizationStepComplete = (values) => {
        this.setState({organizationForSaving: values}, () => {
            this.nextStep();
        });
    };
    
    useraccountStepComplete = (values) => {
        this.setState({userAccountForSaving: values}, () => {
            this.props.register(this.state.userAccountForSaving, this.state.organizationForSaving);
        });
    };
    
    wizardActions = () => {
        const {classes} = this.props;
        return (
            <>
                {this.state.activeStep === (steps.length - 1) &&
                    <Grid container>
                        <Grid item xs>
                            <FormControlLabel
                                control={<Switch checked={this.state.acceptedEthicalGuidance} onChange={(event) => {
                                    this.setState({acceptedEthicalGuidance:  event.target.checked });
                                }} inputProps={{'aria-label': 'primary checkbox'}}/>}
                                label={(<div><span>Ja, jeg har lest og forstått de</span> <Link target="_blank"
                                                                                                href="/etiske-retningslinjer.pdf">etiske
                                    retningslinjene?</Link></div>)}
                            />
                        </Grid>
                    </Grid>
                }
                <Grid container style={{marginTop: 10}}>
                    <Grid item xs>
                        <Button
                            disabled={this.state.activeStep === 0}
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.backButton}
                            onClick={this.prevStep}
                        >
                            Tilbake
                        </Button> &nbsp;
                        {this.state.activeStep !== (steps.length - 1) &&
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.nextButton}
                        >
                            Neste
                        </Button>
                        }
                        {this.state.activeStep === (steps.length-1) &&
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            className={classes.completeButton}
                            disabled={!this.state.acceptedEthicalGuidance}
                        >
                            Registrer
                        </Button>
                        }
                    </Grid>
                    <Grid item>
                        <Link href="#" onClick={() => historyHelper.push("/Login")} variant="body2">
                            {"Har du allerede konto?"}
                        </Link>
                    </Grid>
                </Grid>
            </>
        );
    };
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.org !== this.props.org) {
            let org = this.props.org.entries[0];
            this.setState({
                organizationForSaving: {
                    organizationNr: org.orgnr,
                    name: org.navn,
                    telephone: (org.tlf.length !== 0 ? org.tlf : org.tlf_mobil).replace(/\s/g, ''),
                    streetAddress: org.forretningsadr,
                    zipCode: org.forradrpostnr,
                    city: org.forradrpoststed,
                }
            });
            console.log(this.state);
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({ submitted: true });
        
    };
    
    nextStep = () => {
        if(this.state.activeStep >= steps.length) return;
        this.setState({
            activeStep: this.state.activeStep+1
        }, () => {
            console.log(this.state);
        });
    };
    
    prevStep = () => {
        if(this.state.activeStep === 0) return;
        this.setState({
            activeStep: this.state.activeStep-1
        }, () => {
            console.log(this.state);
        });
    };
    
    render()
    {
        const {classes} = this.props;
        
        return (
            <Grid container component="main" className={classes.root}>
                <CssBaseline/>
                <Grid item xs={false} sm={4} md={7} className={classes.image}/>
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Avatar className={classes.avatar}>
                            <LockOutlinedIcon/>
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Registrer ny leverandør
                        </Typography>

                        <Stepper activeStep={this.state.activeStep} className={classes.stepper}>
                            {steps.map(label => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <div className={classes.fullWidth}>
                            {this.getStepContent(this.state.activeStep)}
                        </div>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default connect(
    state => state.authentication,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(withStyles(styles)(Registration));