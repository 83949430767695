import React, {Component} from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {actionCreators as intranetActions} from "../../../store/Intranet";
import {actionCreators as equipmentActions} from "../../../store/Equipment";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AddEditEquipmentCategory from "./Modal/AddEditEquipmentCategory";
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    paper: {
        overflow: 'hidden',
    },
    block: {
        display: 'block',
    },
    addEquipmentCategory: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 26px',
    },
});

class EquipmentCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAddingEquipmentCategory: false,
            equipmentCategoryForSaving: {}
        }
    }
    cancelAddEditEquipmentCategory = ()=> {
        this.setState({
            isAddingEquipmentCategory: false,
            equipmentCategoryForSaving: {}
        });
    };
    
    addEditEquipmentCategory = (row = null) => {
        if(row !== null) {
            this.setState({
                isAddingEquipmentCategory: true,
                equipmentCategoryForSaving: {
                    equipmentCategoryId: row.equipmentCategoryId,
                    active: row.active,
                    category: row.category,
                }
            });
        } else {
            this.setState({
                isAddingEquipmentCategory: true,
                equipmentCategoryForSaving: {
                }
            });
        }
        
    };
    
    handleAddEditEquipmentCategory = () => {
        this.props.equipmentActions.createOrUpdateCategory(this.state.equipmentCategoryForSaving, this.AddEditEquipmentCategorySuccess);
    };
    
    AddEditEquipmentCategorySuccess = () => {
        this.setState({
            isAddingEquipmentCategory: false,
            equipmentCategoryForSaving: {}
        });
        
    };
    
    handleEquipmentCategoryChange = name => event => {
        const target = event.target;
        const val = target.type === 'checkbox' ? target.checked : target.type === 'number' ? parseFloat(target.value) : target.value;
        this.setState(state => ({
            equipmentCategoryForSaving: {
                ...state.equipmentCategoryForSaving,
                [name]: val
            }
        }));
    };
    
    render() {
        const {classes} = this.props;
        return (
            <div>
                <AddEditEquipmentCategory classes={classes} equipmentCategoryForSaving={this.state.equipmentCategoryForSaving} handleEquipmentCategoryChange={this.handleEquipmentCategoryChange} isAddingEquipmentCategory={this.state.isAddingEquipmentCategory} cancelAddEditEquipmentCategory={this.cancelAddEditEquipmentCategory} handleAddEditEquipmentCategory={this.handleAddEditEquipmentCategory} />

                <h1>Utstyrskategorier</h1>
                <Paper className={classes.paper}>
                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                        <Toolbar>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" className={classes.addEquipmentCategory} onClick={this.addEditEquipmentCategory}>
                                        Legg til utstyrskategori
                                    </Button>
                                    <Tooltip title="Reload">
                                        <IconButton>
                                            <RefreshIcon className={classes.block} color="inherit"/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.contentWrapper}>
                        {this.props.equipment.categories.length !== 0 &&
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Kategori</TableCell>
                                    <TableCell align="left">Aktiv</TableCell>
                                    <TableCell align="left"> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.equipment.categories.map(row => (
                                    <TableRow key={row.equipmentCategoryId}>
                                        
                                        <TableCell align="left">{row.category}</TableCell>
                                        <TableCell align="left">{row.active ? "Ja" : "Nei"}</TableCell>
                                        <TableCell align="right">
                                            <IconButton onClick={() => this.addEditEquipmentCategory(row)} className={classes.button} aria-label="Edit category">
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        }

                        {this.props.equipment.categories.length === 0 &&
                        <Typography color="textSecondary" align="center">
                            Ingen utstyrskategorier tilgjengelig
                        </Typography>
                        }
                    </div>
                </Paper>
            </div>
        )
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    componentDidMount() {
        this.props.equipmentActions.getCategories();
    }
}

function mapDispatchToProps(dispatch) {
    return {
        intranetActions: bindActionCreators(intranetActions, dispatch),
        equipmentActions: bindActionCreators(equipmentActions, dispatch)
    }
}

function mapStateToProps(state) {
    return {
        intranet: state.intranet,
        equipment: state.equipment
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentCategories));
