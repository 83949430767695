import React, {Component} from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core";
import AddEditOrganization from "./Modal/AddEditOrganization";
import {bindActionCreators} from "redux";
import {actionCreators as intranetActions} from "../../../store/Intranet";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
    paper: {
        overflow: 'hidden',
    },
    searchBar: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
        fontSize: theme.typography.fontSize,
    },
    block: {
        display: 'block',
    },
    addUser: {
        marginRight: theme.spacing(1),
    },
    contentWrapper: {
        margin: '40px 26px',
    },
});

class Organizations extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isAddingOrganization: false
        }
    }
    cancelAddOrganization = ()=> {
        this.setState({
            isAddingOrganization: false
        });
    };
    addOrganization = () => {
        this.setState({
            isAddingOrganization: true
    });
    };
    render() {
        const {classes} = this.props;
        return (
            <div>
                <AddEditOrganization isAddingOrganization={this.state.isAddingOrganization}
                                     cancelAddOrganization={this.cancelAddOrganization}/>
                <h1>Leverandører</h1>
                <Paper className={classes.paper}>
                    <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                        <Toolbar>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item>
                                    <SearchIcon className={classes.block} color="inherit"/>
                                </Grid>
                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        placeholder="Søk på orgnr, navn eller telefon nummer"
                                        InputProps={{
                                            disableUnderline: true,
                                            className: classes.searchInput,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" className={classes.addUser} onClick={this.addOrganization}>
                                        Legg til leverandør
                                    </Button>
                                    <Tooltip title="Reload">
                                        <IconButton>
                                            <RefreshIcon className={classes.block} color="inherit"/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                    <div className={classes.contentWrapper}>
                        {this.props.intranet.organizations.length !== 0 &&
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>OrgNr</TableCell>
                                    <TableCell align="left">Navn</TableCell>
                                    <TableCell align="left">Telefon</TableCell>
                                    <TableCell align="left">Addresse</TableCell>
                                    <TableCell align="left">Postnummer</TableCell>
                                    <TableCell align="left">Poststed</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.props.intranet.organizations.map(row => (
                                    <TableRow key={row.organizationId}>
                                        <TableCell component="th" scope="row">
                                            {row.organizationNr}
                                        </TableCell>
                                        <TableCell align="left">{row.name}</TableCell>
                                        <TableCell align="left">{row.telephone}</TableCell>
                                        <TableCell align="left">{row.streetAddress}</TableCell>
                                        <TableCell align="left">{row.zipCode}</TableCell>
                                        <TableCell align="left">{row.city}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        }

                        {this.props.intranet.organizations.length === 0 &&
                        <Typography color="textSecondary" align="center">
                            Ingen organisasjoner tilgjengelig
                        </Typography>
                        }
                    </div>
                </Paper>
            </div>
        )
    };

    componentDidMount() {
        this.props.intranetActions.getOrganizations();
    }
    componentWillUnmount() {
        console.log(123)
    }
}

function mapDispatchToProps(dispatch) {
    return {
        intranetActions: bindActionCreators(intranetActions, dispatch),
    }
}

function mapStateToProps(state) {
    return {
        intranet: state.intranet,
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Organizations));
