import React, {useEffect, useState} from 'react';
import * as Yup from "yup";
import {Field, Form, Formik} from "formik";
import FormControl from "@material-ui/core/FormControl";
import {TextField} from "formik-material-ui";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

const AddUser = ({isOpen, setIsOpen, classes, addUser}) => {
    return (
        <div>
            <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"xs"}
            fullWidth={true}>
            <DialogTitle id="alert-dialog-title">
                Legg til bruker
            </DialogTitle>
            <DialogContent>
            <Formik
                initialValues={{
                    email: ''
                }}
                validationSchema={Yup.object({
                    email: Yup.string()
                        .email('Må være en gyldig epost.')
                        .required('Påkrevd.'),
                })}
                onSubmit={(values, {setSubmitting}) => {
                    setSubmitting(false);
                    console.log(values);
                    addUser({organizationId: null, email: values.email}, () => { setIsOpen(false)});
                    //this.props.login(values.email, values.password);
                    //this.props.useraccountStepComplete(values);
                }}>
                <Form className={classes.form}>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <Field
                            margin="normal"
                            component={TextField}
                            name="email"
                            label="E-post"
                            variant="outlined"
                        />
                    </FormControl>
                    <DialogActions>
                        <Button color="primary" onClick={() => setIsOpen(false)}>
                            Avbryt
                        </Button>
                        <Button color="primary" type={"submit"}>
                            Legg til
                        </Button>
                    </DialogActions>
                </Form>
            </Formik>
            </DialogContent>
            </Dialog>
        </div>
    );
};

export default (AddUser);